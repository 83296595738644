@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.Tooltip {
  font-size: 90%;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  width: max-content;
  max-width: 300px;
  z-index: 9090;

  &.dark {
    background-color: #444;
    color: white;
  }

  &.light {
    background-color: white !important;
    box-shadow: 0 3px 6px 4px #0000002d;
  }
}
