@use '../../../styles/presets' as pres;
@use '../../../styles/variables' as vars;

#multiseat-purchase-page,
#multiseat-review-order-page,
#student-course-access-purchase-page,
#student-course-access-review-order-page,
#payment-complete-page,
#payment-cancelled-page {
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form,
  .purchase-menu {
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    position: relative;
    padding: 3rem;
    animation: fadeIn 0.25s;

    h1 {
      align-self: stretch;
      margin-bottom: 1rem;
      text-align: center;
    }

    img {
      margin-bottom: 1rem;
      position: absolute;
      bottom: 100%;
    }

    .confirm-btn {
      padding: 1rem;
      align-self: stretch;
      font-size: vars.$font-size-xs;
      vertical-align: middle;

      i {
        vertical-align: middle;
        font-size: vars.$font-size-sm;
        margin: 0 0.25rem;
      }
    }
  }
}

#multiseat-purchase-page {
  form {
    p,
    .input-wrapper {
      margin-bottom: 2rem;
    }

    .input-wrapper {
      font-size: vars.$font-size-sm;
      font-weight: bold;

      input[type='number'] {
        border-radius: 0.25rem;
        text-align: center;
        padding: 1rem;
        width: 128px;
        margin: 0 1rem;
        font-weight: bold;
      }

      span {
        display: inline-block;
        width: 64px;
      }

      input[type='text'] {
        font-size: vars.$font-size-xxs;
        width: 256px;

        &:disabled {
          opacity: 0.6;
        }
      }

      p#code-ex {
        font-weight: normal;
        margin: 0.25rem 0 0 0;
        text-align: center;
      }
    }

    button#menu-toggle {
      @extend %default-btn;
      margin-top: 1rem;

      b {
        color: vars.$link-text-color;
        &:hover {
          color: lighten(vars.$link-text-color, 15%);
        }
      }
    }
  }
}

#multiseat-review-order-page,
#student-course-access-review-order-page {
  form {
    padding: 2rem 3rem 3rem 3rem;
    font-size: vars.$font-size-xxs;

    #back-btn {
      align-self: flex-start;
      margin-bottom: 0.5rem;
      margin-left: -1rem;

      i {
        vertical-align: middle;
        margin-right: 0.5rem;
        font-size: 20px;
      }
    }

    table {
      @extend %table-no-borders;

      th,
      td {
        padding: 0.5rem 1rem;

        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }

        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }

      th {
        text-align: left;
      }

      td {
        text-align: right;
      }

      tr:nth-child(odd) {
        background-color: rgb(245, 245, 245);
      }
    }

    .tos-wrapper {
      padding: 1rem 0;

      input[type='checkbox'] {
        margin-right: 1rem;
      }
    }
  }
}

#student-course-access-purchase-page {
  #or-divider {
    align-self: stretch;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 1.5rem 0;
    position: relative;
    font-size: vars.$font-size-xxs;

    span {
      padding: 0 1rem;
      background-color: white;
      position: relative;
      z-index: 1;
    }

    hr {
      position: absolute;
      width: 100%;
      top: 1.5ex;
      margin: 0;
    }
  }

  form {
    padding: 0;

    input[type='text'] {
      margin-right: 1rem;
    }

    .tos-wrapper {
      margin-top: 1rem;
      text-align: center;

      input[type='checkbox'] {
        margin-right: 0.5rem;
        cursor: pointer;
      }

      label {
        margin: 0;
      }
    }
  }
}

#payment-complete-page,
#payment-cancelled-page {
  h1 {
    i {
      font-size: vars.$font-size-md;
    }

    i#success-icon {
      color: vars.$success-color;
    }

    i#failure-icon {
      color: vars.$cancel-color;
    }
  }

  p.purchase-details {
    text-align: center;
  }

  #redirect-btn {
    margin-top: 1rem;
  }
}

#sharing-menu {
  p {
    margin: 0 0 1rem 0;
    max-width: 320px;
  }

  .alert-bar {
    margin-bottom: 1rem;
  }

  form {
    @extend %flex-row;

    input {
      flex-grow: 1;
      margin-right: 0.5rem;
    }
  }

  h2 {
    font-size: vars.$font-size-xs;
    margin-top: 1rem;
  }

  table {
    @extend %table-no-borders;
    width: 100%;

    th,
    td {
      padding: 0.5rem;
    }

    button {
      float: right;
    }
  }
}
