@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

$icon-back-color: #68bde2;

.counter-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 1rem 2rem 0;
  user-select: none;

  .icon-wrapper {
    @extend %flex-row-jc-ac;
    @include pres.width-height(4rem);
    position: relative;
    animation: icon-grow 2s ease-out, icon-click-in-place 0.2s 2s;

    i {
      @extend %flex-row-jc-ac;
      @include pres.width-height(4rem);
      position: absolute;
      background-color: $icon-back-color;
      font-size: vars.$font-size-md;
      color: rgba($color: #200426, $alpha: 0.3);
      border-radius: 50%;
      animation: icon-bounce 2s cubic-bezier(0.22, 1, 0.36, 1);

      @keyframes icon-bounce {
        @for $i from 0 through 33 {
          @if $i % 2 == 0 {
            #{$i * 3%} {
              transform: translateY(-1.6rem) scale(0.8, 1.4);
            }
          } @else {
            #{$i * 3%} {
              transform: translateY(0.5rem) scale(1.4, 0.8);
            }
          }
        }
      }

      @keyframes icon-grow {
        from {
          transform: scale(0.5);
        }
        to {
          transform: scale(0.9);
        }
      }

      @keyframes icon-click-in-place {
        from {
          transform: scale(0.9);
        }
        to {
          transform: scale(1);
        }
      }

      &::after {
        @include pres.width-height(0.01rem);
        content: '';
        position: absolute;
        opacity: 0;
        background-color: $icon-back-color;
        border-radius: 50%;
        animation: finish-highlight 1s 2s;

        @keyframes finish-highlight {
          0% {
            opacity: 1;
          }
          100% {
            @include pres.width-height(20rem);
            opacity: 0;
          }
        }
      }
    }
  }

  .count-wrapper {
    @include pres.inset(auto, 0, 0, auto);
    position: absolute;

    .count {
      margin: 0 -0.5em -0.8em 0;
      font-size: 36px;
      font-weight: bold;
      animation: counter-grow 2s ease-out, counter-click-in-place 0.2s 2s;

      @keyframes counter-grow {
        from {
          font-size: 22px;
        }
        to {
          font-size: 30px;
        }
      }

      @keyframes counter-click-in-place {
        from {
          font-size: 30px;
        }
        to {
          font-size: 36px;
        }
      }
    }
  }
}
