@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.peer-textbox {
  width: 100%;
  min-height: 10rem;
  padding-top: 1.6rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  animation: fadeIn 0.2s;

  label {
    position: absolute;
    top: 0;

    h2 {
      display: inline;
      font-size: vars.$font-size-xs;
    }

    &::after {
      font-size: vars.$font-size-xs;
      margin-left: 0.5rem;
    }
  }

  textarea {
    flex-grow: 3;
    padding: 1rem;
    border-radius: 1rem 1rem 0 0 !important;
    border-bottom: none !important;
    box-shadow: none !important;
    resize: none;

    &:required + label::after {
      content: '*';
      color: red;
      display: inline;
      font-weight: bold;
    }

    &:optional + label::after {
      content: '(Optional)';
      color: #525252;
    }

    &:valid {
      outline: none !important;
      border-color: #afafaf !important;
    }

    &:focus,
    &:valid {
      & + label + .text-box-bottom {
        border-color: #afafaf !important;
      }
    }
  }

  .text-box-bottom {
    @extend %flex-row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 1rem 0.25rem 1rem;
    background-color: #f6f6f6;
    border: 1px solid #e5e5e5;
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    transition: border-color 0.2s;
    cursor: text;

    span.word-count {
      color: #88959f;
    }

    .pin-drop-wrapper {
      vertical-align: middle;

      .cancel-pin,
      .unpinned-btn,
      .delete-pin-btn {
        @extend %default-btn;
        vertical-align: middle;
      }

      .unpinned-btn,
      .delete-pin-btn {
        @include pres.width-height(24px);
        padding: 0;
      }

      .unpinned-btn {
        color: vars.$icon-color;

        &:hover,
        &:focus {
          color: vars.$primary-color-2;
        }
      }

      .delete-pin-btn {
        color: vars.$cancel-color;

        &:hover,
        &:focus {
          color: darken(vars.$cancel-color, 15%);
        }
      }

      .cancel-pin {
        border-radius: 0.25rem;

        &:hover,
        &:focus {
          background-color: rgba(112, 112, 112, 0.2);
        }
      }
    }
  }
}
