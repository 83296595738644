@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;
@use './teacher/teacher';
@use './student/student';

#details-card {
  @include pres.panel-padding-radius(1rem, 0.25rem);
  color: vars.$pale-text-color;
  font-size: vars.$font-size-xxs !important;

  .heading {
    @extend %flex-row;
    position: relative;
    margin-bottom: 1rem;
    row-gap: 2rem;
    column-gap: 1rem;
    flex-wrap: wrap;

    .title-wrapper {
      padding-left: 3rem;
      flex-shrink: 1;
      flex-grow: 1;
      min-width: 200px;

      .assignment-phase-icon {
        position: absolute;
        left: 0;
      }

      h1,
      h2 {
        margin: 0;
      }

      h1 {
        font-size: vars.$font-size-xs !important;
        color: vars.$navbar-color;
      }

      h2 {
        font-size: vars.$font-size-xxs !important;
      }
    }

    span.options-wrapper {
      flex-shrink: 0;

      span.publish-toggle-wrapper {
        vertical-align: middle;

        i {
          font-size: 20px;
          user-select: none;
        }

        i,
        select {
          vertical-align: middle;
        }

        select[aria-disabled='true'] {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .curr-task-wrapper {
      @extend %flex-row;
      align-items: baseline;
      flex-shrink: 0;
      flex-grow: 0;

      span:first-child {
        white-space: nowrap;
      }

      button#next-task-btn,
      a#next-task-btn {
        margin: 0;
        font-size: vars.$font-size-xs;
        white-space: nowrap;

        &.submit,
        &.review,
        &.feedback,
        &.evaluate {
          padding-left: 2.75rem;
          position: relative;

          &::before {
            content: '';
            font-family: 'Material Icons';
            position: absolute;
            left: 0.5rem;
            font-size: 24px;
            line-height: 100%;
          }
        }

        &.submit {
          background-color: vars.$submit-color;

          &::before {
            content: 'file_upload';
          }
        }

        &.review {
          background-color: vars.$review-color;

          &::before {
            content: 'rate_review';
          }
        }

        &.feedback {
          background-color: vars.$feedback-color;

          &::before {
            content: 'question_answer';
          }
        }

        &.evaluate {
          background-color: vars.$evaluate-color;

          &::before {
            content: 'supervisor_account';
          }
        }
      }

      .task-time-wrapper {
        @extend %flex-col;
        align-items: stretch;
        text-align: center;
      }

      span {
        margin-right: 1rem;
        font-weight: bold;
      }

      p {
        display: inline-block;
        margin: 0;
      }

      i {
        color: vars.$error-color;
        margin-right: 0.5rem;
        vertical-align: bottom;
      }
    }
  }

  #assignment-description {
    max-height: 16vh;
    padding-top: 0.5rem;
    position: relative;
    overflow: hidden;

    h3#desc-label {
      position: absolute;
      padding: 0 0.25rem;
      top: 0;
      left: 0.25rem;
      font-size: vars.$font-size-xxs;
      box-shadow: inset 0px 0px 16px 0px white;
      border-radius: 0.25rem;
      z-index: 1000;
    }

    #reader-wrapper {
      padding: 0.75rem 0.5rem 0.5rem 0.5rem;
      border: 1px solid vars.$border-soft-color;
      border-radius: 0.25rem;
      background-color: rgb(245, 245, 245);
      overflow: auto;
    }
  }

  button#toggle-description-size {
    @extend %default-btn;
    width: 100%;
    margin: 0.5rem 0 0 0;
    padding: 0;
    text-align: left;
    color: vars.$pale-text-color;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0 !important;

    &.more {
      position: relative;
      box-shadow: 0px -16px 16px 8px white;
    }
  }
}

.now-phase {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;

  &.submit {
    background-color: #d6d6fb;
  }

  &.review {
    background-color: #f8d5f7;
  }

  &.feedback {
    background-color: #f7edc7;
  }

  &.eval {
    background-color: #cbefbf;
  }
  &.reflection {
    background-color: #89cff0;
  }

  .indent {
    padding-left: 0.5rem;
  }

  button#end-phase-btn {
    display: block;
    width: 106px;
    margin: 0 auto;
  }
}
