@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.target-graph {
  .visx-grid-radial:first-child {
    filter: drop-shadow(0 4px #e85050);
  }

  image.arrow {
    animation: arrow 0.4s ease-in;
    animation-fill-mode: backwards;

    @keyframes arrow {
      0% {
        opacity: 0;
        width: 64px;
        height: 64px;
        transform: translate(-28px, -28px);
      }
      50% {
        opacity: 1;
      }
      100% {
        width: 8px;
        height: 8px;
        transform: translate(0, 0);
      }
    }
  }
}

.reviews-chart {
  margin: 0 3rem;
  overflow: visible;
  g {
    overflow: visible;
  }
}

.tracked-performance {
  @include pres.panel-padding-radius(0.5rem 1rem, 1rem);
  align-self: flex-start;
  max-width: 448px;

  h2 {
    font-size: vars.$font-size-xs;
  }

  p {
    color: vars.$pale-text-color;
  }

  h3 {
    font-weight: normal;
    margin-bottom: 1rem;
    text-align: center;
  }

  table {
    margin: 1rem auto 0 auto;

    th.date,
    td.date {
      text-align: left;
    }

    th.score,
    td.score,
    th.trend,
    td.trend {
      text-align: center;
    }

    tr.pr {
      background-color: #fffc9c;

      td.score {
        position: relative;

        &::before {
          content: 'emoji_events';
          font-family: 'Material Icons';
          color: #856f36;
          font-size: 20px;
          line-height: 20px;
          vertical-align: middle;
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

.semester-bar-chart {
  margin: 0 0 1.5rem 5rem;
  overflow: visible;
  g {
    overflow: visible;
  }
}
