$primary-color: #327daf;
$secondary-color: #e38601;
$navbar-color: #395061;
$background-color: #f1f1f1;
$error-color: #982525;
$white: #fafafa;
$off-white: #f6f6f6;
$primary-text-color: #225577;
$secondary-text-color: #a86200;
$link-text-color: #006fe6;
$pale-text-color: #6e6e6e;
$border-soft-color: #e5e5e5;
$icon-color: #53586a;
$primary-color-2: #2e74a3;
$secondary-color-2: #df830a;
$cancel-color: #ec4f4f;
$success-color: #38b934;

$submit-color: #7878f1;
$review-color: #e676e3;
$feedback-color: #e4c445;
$evaluate-color: #55c92d;
$reflect-color: #68e5db;

$font-size-lg: 64px;
$font-size-md: 40px;
$font-size-sm: 25px;
$font-size-xs: 16px;
$font-size-xxs: 14px;

$icon-size: 32px;

$navbar-height: 56px;
$tab-bar-height: 4rem;

$screen-width-laptop-lg: 1439px;
$screen-width-laptop-sm: 1023px;
$screen-width-tablet: 767px;
$screen-width-mobile-lg: 424px;
$screen-width-mobile-sm: 319px;
