@use './variables' as vars;
@use './functions' as func;

// Flex Row
%flex-row {
  display: flex;
  flex-direction: row;
}

// Flex Row: Justify Center, Align Center
%flex-row-jc-ac {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// Flex Row: Justify Center
%flex-row-jc {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

// Flex Row: Align Center
%flex-row-ac {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// Flex Column:
%flex-col {
  display: flex;
  flex-direction: column;
}

// Flex Column: Justify Center, Align Center
%flex-col-jc-ac {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Flex Column: Justify Center
%flex-col-jc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// Flex Column: Align Center
%flex-col-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%default-btn {
  border: none;
  background-color: transparent;
}

%default-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

%text-break-to-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%text-all-caps {
  text-transform: uppercase;
  letter-spacing: 2px;
  word-spacing: 1px;
}

%absolute-and-fill-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

%fixed-and-fill-space {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

%table-no-borders {
  border-collapse: collapse !important;

  tr,
  th,
  td {
    border: none !important;
  }
}

%focus-visible-style {
  box-shadow: 0 0 0 1px white, 0 0 0 3px black, 0 0 0 4px white !important;
  outline: none !important;
}

@mixin panel-padding-radius($padding, $radius) {
  background-color: white;
  padding: $padding;
  border-radius: $radius;
}

/**
 *  Uses:
 *    width-height($width, $height) : Sets width & height separately.
 *    width-height($value) : Sets both width & height to $value.
 */
@mixin width-height($width, $height: $width) {
  width: $width;
  height: $height;
}

/**
 * Cross-browser compatible version of the CSS "inset" property.
 * Must use all 4 arguments.
 */
@mixin inset($top, $right, $bottom, $left) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin restrict-to-view-width($margin) {
  max-width: calc(100vw - #{$margin});
}

@mixin restrict-to-view-height($margin) {
  max-height: calc(100vh - #{vars.$navbar-height} - #{$margin});
}

/**
 * All standard media queries:
 * ("bp" prefix = "breakpoint")
 */
@mixin bp-laptop-lg {
  @media (max-width: vars.$screen-width-laptop-lg) {
    @content;
  }
}
@mixin bp-laptop-md {
  @media (max-width: func.midpoint(vars.$screen-width-laptop-sm, vars.$screen-width-laptop-lg)) {
    @content;
  }
}
@mixin bp-laptop-sm {
  @media (max-width: vars.$screen-width-laptop-sm) {
    @content;
  }
}
@mixin bp-tablet-lg {
  @media (max-width: vars.$screen-width-tablet) {
    @content;
  }
}
@mixin bp-tablet-sm {
  @media (max-width: func.midpoint(vars.$screen-width-tablet, vars.$screen-width-mobile-lg)) {
    @content;
  }
}
@mixin bp-mobile-lg {
  @media (max-width: vars.$screen-width-mobile-lg) {
    @content;
  }
}
@mixin bp-mobile-sm {
  @media (max-width: vars.$screen-width-mobile-sm) {
    @content;
  }
}
@mixin bp-height {
  @media (max-height: 480px) {
    @content;
  }
}
