.loading-spinner-wrapper {
  position: fixed;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  animation: fadeIn 0.5s;
  z-index: 10000;

  svg.loading-spinner {
    animation: svg-spinner 8s infinite;
    transition: opacity 1s;

    circle.outer-circ {
      animation: outer-circ-pulse 3s infinite both;
    }

    circle#outer-circ-2 {
      animation-delay: 1s;
    }
    circle#outer-circ-3 {
      animation-delay: 2s;
    }

    circle.inner-circ {
      animation: inner-circ-pulse 2s infinite both;
    }

    circle#inner-circ-2 {
      animation-delay: 0.67s;
    }

    circle#inner-circ-3 {
      animation-delay: 1.33s;
    }

    @keyframes outer-circ-pulse {
      0% {
        r: 3.7042;
      }
      20% {
        r: 3.7042;
      }
      50% {
        r: 4.5;
      }
      80% {
        r: 3.7042;
      }
      100% {
        r: 3.7042;
      }
    }

    @keyframes inner-circ-pulse {
      0% {
        r: 1.5875;
      }
      10% {
        r: 1.5875;
      }
      50% {
        r: 2.5;
      }
      90% {
        r: 1.5875;
      }
      100% {
        r: 1.5875;
      }
    }

    @keyframes svg-spinner {
      0% {
        transform: rotate(0deg);
      }
      16.67% {
        transform: rotate(360deg);
      }
      33.34% {
        transform: rotate(480deg);
      }
      50.01% {
        transform: rotate(840deg);
      }
      66.68% {
        transform: rotate(960deg);
      }
      83.35% {
        transform: rotate(1320deg);
      }
      100% {
        transform: rotate(1440deg);
      }
    }
  }
}
