@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.category-menu {
  @include pres.panel-padding-radius(2rem, 2rem);

  h1 {
    padding: 0 2rem 1rem 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid vars.$border-soft-color;
  }

  .menu-body {
    @extend %flex-row;
    align-items: stretch;
    flex-wrap: wrap;

    .links,
    .content {
      max-width: 100%;
    }

    .links {
      display: inline;
      padding: 0 2rem 0 1.5rem;
      border-right: 1px solid vars.$border-soft-color;
      margin-right: 2rem;
      flex-shrink: 0;

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 0.5rem;
          position: relative;

          a {
            background-color: white;
            position: relative;
            z-index: 1;
            padding: 0 0.5rem;
          }
        }

        li.selected {
          font-weight: bold;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -0.5rem;
            right: -1rem;
            border-bottom: 2px solid vars.$secondary-color-2;
          }
        }
      }
    }

    .content {
      padding: 1rem;

      .inputs-container-row {
        flex-wrap: wrap;
      }
    }
  }
}
