@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.peer-form-container {
  width: 100%;
  overflow: hidden;

  .peer-form {
    @extend %flex-col;
    width: 100%;
    justify-content: space-between;
    flex: 1 0 auto;
    overflow-wrap: break-word;
    position: relative;

    .peer-form-page {
      @extend %flex-col;
      flex: 1 0 auto;

      .peer-form-header {
        @include pres.panel-padding-radius(1rem, 0.5rem);
        margin-bottom: 0.5em;

        h1 {
          text-align: center;
          font-size: vars.$font-size-xs;
          line-height: vars.$font-size-xs;
        }

        h2 {
          margin-bottom: 2rem;
        }

        .form-description {
          width: 100%;
        }
      }

      .peer-form-body {
        @extend %flex-col-ac;
        @include pres.panel-padding-radius(1rem, 0.5rem 0.5rem 0 0);
        flex-grow: 3;
        justify-content: stretch;
        pointer-events: auto;

        .peer-textbox:last-child {
          margin-bottom: 0 !important;
        }
      }

      .peer-form-row {
        @extend %flex-row;
        align-items: stretch;
        padding: 1em 0;
        flex: 1 0 auto;
      }

      .peer-form-col {
        @extend %flex-col;
        padding: 0 2em;
        flex: 0 0 50%;
        border-right: 1px solid vars.$border-soft-color;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
    }

    .page-exit-left {
      position: absolute;
      width: 100%;
      animation: fadeOutLeft 0.5s forwards;
    }

    .page-exit-right {
      position: absolute;
      width: 100%;
      animation: fadeOutRight 0.5s forwards;
    }

    .page-enter-left {
      animation: fadeInLeft 0.5s forwards;
    }

    .page-enter-right {
      animation: fadeInRight 0.5s forwards;
    }

    .peer-form-footer {
      @include pres.panel-padding-radius(1rem, 0 0 0.5rem 0.5rem);
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-wrap: wrap;
      position: relative;
      border-top: 1px solid vars.$border-soft-color;
      margin-bottom: 3rem;

      .form-last-saved-wrapper {
        text-align: center;
        transition: opacity 0.5s;

        span {
          color: vars.$pale-text-color;
        }
      }

      .progress-bar-wrapper {
        position: absolute;
        top: calc(100% + 1rem);
        left: 0;
        right: 0;
        max-width: 512px;
        margin: 0 auto;
      }
    }
  }
}

.single-form {
  @extend %flex-col;
  align-content: stretch;

  .header-wrapper {
    @extend %flex-row-ac;
    justify-content: space-between;
    margin-bottom: 2rem;

    .title-content {
      flex-grow: 1;
      vertical-align: middle;

      .exit-btn,
      h1 {
        vertical-align: middle;
      }

      .exit-btn {
        margin-right: 1rem;
      }

      h1 {
        display: inline-block;
        margin: 0;
      }
    }

    button.top-btn {
      margin-left: 0.5rem;
    }

    .custom-ctrls-wrapper {
      margin-left: 0.5rem;
    }
  }

  .form-group {
    margin-bottom: 2rem;
  }

  .input-group {
    @extend %flex-row-ac;
    @extend %text-break-to-ellipsis;

    margin-bottom: 1rem;
    flex-wrap: nowrap !important;

    i {
      margin-right: 1rem;
    }
  }

  .inputs-container-col,
  .inputs-container-row {
    flex-grow: 1;
  }

  .inputs-container-row {
    @extend %flex-row;

    input,
    select {
      margin: 0 0.5rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .inputs-container-col {
    @extend %flex-col;

    input,
    select {
      margin: 0 0 0.5rem 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  form {
    .peer-form-row {
      @extend %flex-row;
      align-items: stretch;
      padding: 1em 0;
      flex: 1 0 auto;
    }

    .peer-form-col {
      @extend %flex-col;
      padding: 0 2em;
      flex: 0 0 50%;
      border-right: 1px solid vars.$border-soft-color;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  button[type='submit'] {
    &:disabled {
      cursor: wait;
    }
  }
}

@include pres.bp-laptop-sm {
  .peer-form-row {
    flex-direction: column !important;
  }

  .peer-form-col {
    border-right: none !important;
    border-bottom: 1px solid #dbdbdb;
    padding: 2rem 0 !important;

    &:first-child {
      padding-top: 0 !important;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

@include pres.bp-tablet-sm {
  .peer-form > .peer-form-footer {
    #form-btn-previous,
    #form-btn-next,
    #form-btn-submit {
      flex: 1 1 calc(100% * (5 / 12));
      padding: 0.6em 0.6em;
      margin-right: 0.6em;
    }
  }
}

@include pres.bp-mobile-sm {
  .peer-form > .peer-form-footer {
    #form-btn-previous,
    #form-btn-next,
    #form-btn-submit {
      margin: 0 0 1em 0;
    }
  }
}
