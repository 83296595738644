@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.modal-overlay {
  @extend %flex-col-ac;
  @extend %fixed-and-fill-space;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  z-index: 9990;
  background-color: #000000a2;
  animation: fadeIn 0.25s;

  @include pres.bp-height {
    justify-content: flex-start !important;
  }

  .panel {
    @extend %flex-col-ac;
    margin: 2rem 0;
    max-width: calc(100vw - 2rem);
    flex: 0 0 auto;
    animation: fadeIn 0.5s;

    .modal-content {
      max-width: 100%;
      padding: 0.5rem;
    }

    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 0.5rem;
    }

    h1 {
      font-size: vars.$font-size-sm;
      text-align: center;
    }

    input[type='text'].modal-input {
      padding: 0.5rem;
      background-color: transparent !important;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid vars.$pale-text-color;
      text-align: center;
      box-shadow: none;
    }

    label,
    p {
      margin: 2rem 0;
    }

    .modal-tabs {
      @extend %flex-row;
      justify-content: space-evenly;
      margin-bottom: 1rem;

      span {
        font-weight: bold;
        padding: 0.5rem 1.6rem;
        margin: 0 0.5rem;
        border-bottom: 2px solid vars.$border-soft-color;
      }

      span.selected {
        border-color: vars.$primary-color-2;
      }
    }

    .modal-tab {
      @extend %flex-col-ac;
      align-self: stretch;
    }

    .ctrls {
      @extend %flex-row;
      flex-wrap: wrap;

      button,
      a {
        margin: 0.5rem !important;
        flex: 1 0 auto;
      }
    }
  }
}
