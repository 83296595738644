@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.searchbar {
  position: relative;

  &::before {
    content: 'search';
    font-family: 'Material Icons';
    font-size: vars.$font-size-sm;
    position: absolute;
    left: 0.8rem;
    top: 0.25rem;
    color: vars.$pale-text-color;
  }

  input[type='text'] {
    padding: 0.5rem 2.5rem;
    width: 100%;
    border-radius: 10rem;
    background-color: white;
  }

  button.clear-search {
    @extend %default-btn;
    color: vars.$pale-text-color;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    opacity: 0;
    transition: opacity 0.2s;
  }
}
