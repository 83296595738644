@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.scheduler {
  p.explainer {
    font-weight: bold;

    span.green {
      color: #549e52;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
    font-size: vars.$font-size-xxs;

    tr {
      height: 24px; //1.5rem;
    }

    tr:first-child th {
      border-right: transparent;
    }

    th {
      border-color: transparent;
      padding: 0;
      border-bottom: 1px solid #b9b9b9;

      &:first-child {
        text-align: end;
        padding: 0.25rem 0.5rem 0.25rem 0;
        border-bottom: transparent;
        border-right: 1px solid #b9b9b9;
        width: 80px;
      }

      .low {
        font-size: 12px !important;
        font-weight: normal;
      }
    }

    td {
      position: relative;
      background-color: #dedede;
      border-color: #b9b9b9;
      text-align: center;

      input[type='checkbox'] {
        @extend %absolute-and-fill-space;
        @include pres.width-height(100%);
        opacity: 0;
        cursor: pointer;

        & + .visual-check-state {
          @extend %absolute-and-fill-space;
        }

        &:focus-visible + .visual-check-state {
          @extend %focus-visible-style;
        }

        &:checked + .visual-check-state {
          background-color: #50ff79;
        }
      }

      span.percent-available {
        position: relative;
        z-index: 9000;

        &.full {
          font-weight: bold;
        }
      }
    }
  }

  &:not(.read-only) {
    table td input[type='checkbox'] {
      & + .visual-check-state {
        pointer-events: none;
      }

      &:hover + .visual-check-state {
        box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.35);
      }
    }
  }
}
