@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

button.option-btn,
a.option-btn {
  @extend %flex-row-jc-ac;
  @extend %default-btn;
  @include pres.width-height(4rem);
  padding: 0;
  border-radius: 50%;
  box-shadow: 0px 6px 8px 5px rgba(212, 212, 212, 0.144),
    0px -6px 8px 5px rgba(255, 255, 255, 0.8),
    inset 0px 6px 8px 5px rgba(212, 212, 212, 0.144),
    inset 0px -6px 8px 5px rgba(255, 255, 255, 0.8);
  transition: box-shadow 0.2s;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:hover {
    i {
      color: #292929;
    }
  }

  &:active {
    box-shadow: none;
  }

  i {
    font-weight: normal;
    color: #4f4f4f;
    font-size: vars.$font-size-md;
    transition: color 0.2s;
  }
}

@include pres.bp-mobile-lg {
  button.option-btn,
  a.option-btn {
    width: 3rem;
    height: 3rem;

    i {
      font-size: midpoint(vars.$font-size-md, vars.$font-size-sm);
    }
  }
}