@use "variables" as vars;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@mixin setupAvenirNext($font-file, $font-weight) {
  @font-face {
    font-family: 'AvenirNext';
    src: local($font-file), url('../fonts/' + $font-file + '.ttf') format('truetype');
    font-weight: $font-weight;
    font-style: normal;
  }
}

@include setupAvenirNext('AvenirNext-UltraLight', 200);
@include setupAvenirNext('AvenirNext-Regular', 400);
@include setupAvenirNext('AvenirNext-Medium', 600);
@include setupAvenirNext('AvenirNext-Heavy', 800);

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: vars.$font-size-md;
}

h2 {
  font-size: vars.$font-size-sm;
}

h3 {
  font-size: vars.$font-size-xs;
}

p {
  font-size: vars.$font-size-xxs;
  margin-top: 0;
  margin-bottom: 1rem;
}
