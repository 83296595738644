@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;

#course-page {
  color: vars.$navbar-color;
  align-items: center;
  flex-direction: column;

  #course-menu {
    width: 100%;
    max-width: 1200px;
  }

  .ctrls-row {
    @extend %flex-row-ac;
    flex-wrap: wrap;

    & > button,
    & > a,
    & > .peer-dropdown {
      margin: 0.25rem;
    }

    #new-course-dropdown,
    #new-course-template-dropdown {
      margin-right: 0.25rem;

      button {
        padding-right: 0.5rem;

        i {
          margin: 0 0.25rem 0 0.5rem;
          vertical-align: middle;
          font-size: 18px;
        }
      }
    }
  }

  .alert-bar {
    margin-top: 1rem;
    width: 100%;
    max-width: calc(1200px - 2rem);
  }
}

section.past-courses {
  .course-card {
    background-color: #ededff;

    .course-details,
    .label-row,
    p.roster-count-wrapper,
    .card-menu-wrapper button > i {
      color: black !important;
    }

    i.watermark {
      color: #fcf6ff;
    }
  }
}

#course-list-table {
  align-self: stretch;

  .item-container {
    @extend %flex-row;
    flex-wrap: wrap;
  }

  .peer-button {
    margin: 0;
  }
}

.course-card,
.course-card-long {
  height: 12rem;
  position: relative;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #5959c7;
  color: white;

  .course-details,
  .label-row {
    z-index: 10;
  }

  .label-row {
    @extend %flex-row;
    justify-content: space-between;
  }

  .watermark-wrapper {
    @include pres.inset(0, 0, 0, 0);
    position: absolute;
    overflow: hidden;
    pointer-events: none;

    i.watermark {
      @include pres.inset(auto, -3rem, -3rem, auto);
      position: absolute;
      color: #8066c9;
      z-index: 1;
      font-size: 16rem;
      user-select: none;
    }
  }
}

.course-card {
  @extend %flex-col;
  width: 18rem;
  justify-content: space-between;
  align-content: stretch;
  margin: 0.25rem;
  user-select: none;
  cursor: pointer;
  animation: fadeIn 0.5s both;

  h2 {
    font-size: vars.$font-size-xs;
    max-height: 4.5rem;
    overflow: hidden;
  }

  p.roster-count-wrapper {
    @include pres.inset(auto, 1rem, 0.5rem, auto);
    position: absolute;
    margin: 0 !important;
    color: white;
    z-index: 30;

    i {
      vertical-align: text-bottom;
    }

    span {
      margin-left: 0.5ch;
    }
  }

  .card-menu-wrapper {
    @include pres.inset(0.5rem, 0.5rem, auto, auto);
    position: absolute;
    z-index: 1000;

    button > i {
      color: white !important;
    }

    .peer-dropdown .content {
      font-size: vars.$font-size-xxs !important;
    }
  }

  p {
    margin: 0 0 1rem 0 !important;
  }
}

.course-ghost {
  width: 18rem;
  height: 12rem;
  border-radius: 1rem;
  margin: 0.25rem;
}

.course-card-banner-wrapper {
  @extend %flex-row-jc;
  background-color: #5959c7;
  align-self: stretch;
  background-image: url('/assets/patterns/brand-pattern-base-transparent.svg'),
    url('/assets/patterns/brand-pattern-base-transparent.svg'),
    url('/assets/patterns/brand-pattern-base-transparent.svg'),
    url('/assets/patterns/brand-pattern-base-transparent.svg');
  background-size: 40px;
  background-repeat: repeat-x;
  background-position: 0 -10px, 20px 30px, 0 70px, 20px 110px;
  overflow: hidden;
}

.course-card-long {
  @extend %flex-row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 8rem;
  border-radius: 0;
  padding: 0.5rem 3rem 0.5rem 1rem;
  flex-grow: 1;
  max-width: 1200px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2rem 1rem #5959c7;

  h1 {
    font-size: vars.$font-size-sm;
    line-height: vars.$font-size-sm;
  }

  p {
    margin-bottom: 0.5rem;
  }

  section.card-main-section {
    @extend %flex-col-jc;
    overflow: hidden;

    h1 {
      @extend %text-break-to-ellipsis;
    }
  }

  section.course-ctrls {
    @extend %flex-row;
    position: absolute;
    right: 0.5rem;

    a,
    button {
      @include pres.width-height(2rem);
      padding: 0.2rem;
      margin-bottom: 0.1rem;

      i {
        font-size: 22px;
        line-height: 22px;
      }
    }
  }

  section {
    z-index: 20;
  }

  .watermark-wrapper {
    i.watermark {
      @include pres.inset(auto, 3rem, -2rem, auto);
      font-size: 11rem;
    }
  }
}

.course-form {
  margin: 4rem 16rem;
  max-width: 100%;
}

figure.course-card-preview {
  @extend %flex-col-ac;
}

#course-page {
  flex-direction: column;

  h2 {
    margin-bottom: 1rem;
  }

  ul {
    @extend %default-list;
  }

  #edit-course,
  #share-course-code,
  #share-course-by-mail,
  #delete-template {
    margin-right: 0.1rem;
  }

  li.assignment-card {
    justify-content: space-between !important;
    .submit,
    .review,
    .feedback,
    .evaluate,
    .reflection {
      border: none;
      color: white;

      i {
        vertical-align: -0.5rem;
      }
    }

    .submit {
      background-color: vars.$submit-color;
    }

    .review {
      background-color: vars.$review-color;
    }

    .feedback {
      background-color: vars.$feedback-color;
    }

    .evaluate {
      background-color: vars.$evaluate-color;
    }

    .complete {
      border-color: #38b934;
      color: #38b934;
    }

    .reflection {
      background-color: vars.$reflect-color;
    }

    .left-wrapper,
    .right-wrapper {
      @extend %flex-row-ac;
    }

    .left-wrapper {
      flex: 0 1 40%;
    }

    .right-wrapper {
      flex: 0 1 60%;
      justify-content: space-evenly;
    }

    .task-wrapper {
      @extend %flex-row-ac;
      justify-content: flex-end;
      padding-left: 1.5rem;
      flex-basis: 25%;

      p {
        text-align: center;
      }

      .btn-wrapper {
        p.time-left {
          font-weight: bold;
          text-align: center;
          bottom: -1.5rem;
          font-size: 12px;
          color: vars.$pale-text-color;
          height: 0;
          line-height: 1.5rem;
        }
      }
    }

    .phase-progress {
      @extend %flex-row-ac;
      padding-bottom: 1rem;

      span.submit-review-bar,
      span.review-feedback-bar,
      span.feedback-reflection-bar {
        @include pres.width-height(40px, 0.5rem);
        margin: 0 -1px;
      }

      span.submit-review-bar {
        background: linear-gradient(to right, #7878f1, #e676e3);
      }

      span.review-feedback-bar {
        background: linear-gradient(to right, #e676e3, #e4c445);
      }

      span.feedback-reflection-bar {
        background: linear-gradient(to right, #e4c445, vars.$reflect-color);
      }

      .phase-wrapper {
        position: relative;

        span.deadline {
          position: absolute;
          margin-top: 0.25rem;
          left: -0.5rem;
          right: -0.5rem;
          text-align: center;
          color: vars.$pale-text-color;
        }

        span.deadline.current {
          font-weight: bold;
          color: black !important;
        }
      }

      .hide-phase {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  li.no-results {
    color: vars.$pale-text-color;
  }

  .ctrls {
    @extend %flex-row-ac;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 0 0.5rem 0;

    a,
    fieldset,
    .searchbar {
      margin-bottom: 0.5rem;
      font-size: vars.$font-size-xxs;
    }

    #new-assignment-btn {
      padding: 0.5rem;
      margin-right: 1rem;

      i {
        font-size: 20px;
        vertical-align: middle;
      }
    }

    .filter-btn {
      margin: 0.125rem !important;

      label {
        padding: 0.25rem 0.5rem;
      }
    }
  }

  i#search-query {
    color: vars.$link-text-color;
  }

  section.data-bar {
    .graphs-wrapper {
      #interactions-card,
      #activity-card {
        @include pres.panel-padding-radius(0.5rem, 1rem);
        margin: 0 0.5rem;
        max-width: 600px;
        flex-shrink: 0;

        h2 {
          margin-left: 0.5rem;
          font-size: vars.$font-size-xs;
          line-height: vars.$font-size-xs;
        }
      }

      #interactions-card {
        h2 {
          text-align: center;
          margin: 0 0 0.5rem 0;
        }

        .chart-wrapper {
          @extend %flex-row-jc-ac;
          svg {
            margin: 0 0.5rem;
          }
        }

        table.interactions-table {
          @extend %table-no-borders;
          font-size: vars.$font-size-xxs;
          line-height: vars.$font-size-xxs;
          margin-top: 0.5rem;

          th,
          td {
            padding: 0.25rem;
          }

          .color-box {
            @include pres.width-height(1rem);
            display: inline-block;
            border-radius: 4px;
            margin-right: 0.5rem;
            vertical-align: middle;
          }

          td.total,
          td.today {
            color: vars.$pale-text-color;
          }

          td.total {
            text-align: right;
          }

          td.today {
            font-weight: bold;
          }
        }
      }
    }
  }

  section.assignment-list-content {
    @extend %flex-col;
    width: 100%;
    max-width: 1200px;
    flex-grow: 1;
    padding: 0 1rem;

    .list-wrapper {
      flex-grow: 1;
      position: relative;
      overflow: hidden;
      margin: 0 -1rem;
      min-height: 96px;

      ul {
        padding: 0 1rem;
      }
    }
  }
}

#new-course-menu-wrapper {
  @extend %flex-col-jc;
  flex-grow: 1;

  #new-course-menu {
    flex-direction: column;
    flex-grow: 0;

    h1 {
      font-size: vars.$font-size-sm;
      margin-bottom: 2rem;
    }

    a {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}

#course-list-table {
  .table-ctrls-top {
    display: none;
  }

  tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }

  .async-icon,
  .sync-icon {
    @include pres.width-height(24px);
    line-height: 20px;
    font-size: vars.$font-size-xs;
    color: white;
    border-radius: 0.25rem;
    vertical-align: middle;
  }

  .async-icon {
    background-color: #ff9e30;
    border: solid 2px #a35700;
  }

  .sync-icon {
    background-color: #308aff;
    border: solid 2px #0047a3;
  }
}

#share-course-code-modal,
#share-course-via-email-modal {
  p {
    margin: 0;
    text-align: center;
  }

  .code-wrapper {
    @extend %flex-row;
    align-items: baseline;

    button {
      padding: 0.5rem;

      i {
        font-size: 20px;
      }
    }
  }
  .email-wrapper {
    padding-top: 1em;
    text-align: center;
  }
}

@include pres.bp-laptop-sm {
  #course-page li.assignment-card {
    .left-wrapper {
      flex-grow: 1;
    }

    .right-wrapper {
      flex-direction: column !important;
      align-self: stretch;
      justify-content: space-between;
      align-items: center;
      flex-basis: 40%;

      .timeline-wrapper {
        padding: 0.5rem 0;
      }

      .task-wrapper {
        padding: 1rem 0 !important;
      }
    }
  }
}

@include pres.bp-tablet-lg {
  .item-container {
    justify-content: center !important;
  }

  #course-page {
    li.assignment-card {
      flex-wrap: wrap;
      border-radius: 3rem;

      .left-wrapper,
      .right-wrapper {
        flex-basis: 100% !important;
      }

      .right-wrapper {
        margin-top: 0.5rem;
        justify-content: center;
      }
    }
  }
}

@include pres.bp-tablet-sm {
  #course-page {
    .ctrls {
      flex-direction: column;
    }
  }
}

@include pres.bp-tablet-sm {
  .course-card-long {
    section {
      flex: 0 0 100%;
    }

    section.card-main-section {
      padding: 0;
    }
  }
}

@include pres.bp-mobile-lg {
  #course-page {
    li.assignment-card {
      padding: 1rem 1rem 2rem 1rem;

      .left-wrapper {
        flex-basis: 100%;

        .assignment-icon {
          i {
            vertical-align: 0.2rem;
          }
        }
      }
    }

    .course-card-long {
      h1 {
        font-size: vars.$font-size-xs !important;
        line-height: vars.$font-size-xs !important;
      }
    }
  }
}
