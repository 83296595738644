@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.tags-input {
  @extend %flex-row;
  flex-wrap: wrap;
  gap: 1rem;
  cursor: text;
  position: relative;
  overflow: visible;

  .tag {
    @extend %flex-row-ac;
    @include pres.panel-padding-radius(0.5rem 0.8rem, 10rem);
    background-color: white;
    animation: fadeIn 0.5s;

    button.remove-tag {
      @extend %default-btn;
      margin-right: 0.5rem;
      padding: 0.25rem;

      i {
        font-size: vars.$font-size-xs;
        vertical-align: middle;
        user-select: none;
        pointer-events: none;
      }
    }

    p {
      margin: 0;
      flex-grow: 1;
      text-align: center;
    }
  }

  .input-wrapper {
    @extend %flex-row-ac;
    position: relative;
    flex-grow: 1;
    flex-basis: 100%;

    input[type='text'] {
      margin: 0;
      border: none;
      padding: 0.5rem 3rem 0.5rem 0.8rem;
      width: 100%;
      text-align: start;
    }

    button.add-tag {
      @extend %default-btn;
      @include pres.width-height(2rem);
      background-color: vars.$primary-color-2;
      padding: 0;
      position: absolute;
      right: 0.5rem;
      color: white;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.1s;

      &:hover {
        background-color: lighten(vars.$primary-color-2, 5%);
      }

      i {
        vertical-align: middle;
      }
    }
  }

  .search-results {
    @include pres.panel-padding-radius(0, 0.25rem);
    border: 1px solid vars.$border-soft-color;
    position: absolute;
    top: 100%;
    width: 100%;

    .option-scroller {
      max-height: 160px;
    }

    .option {
      @extend %text-break-to-ellipsis;
      padding: 0.25rem 0.5rem;
      font-size: vars.$font-size-xxs;
      user-select: none;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: rgb(238, 238, 238);
      }

      &:not(:last-child) {
        border-bottom: 1px solid vars.$border-soft-color;
      }

      span.highlight {
        font-weight: bold;
        background-color: rgb(165, 255, 255);
      }
    }
  }
}
