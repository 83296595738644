@use "../../styles/presets" as pres;
@use "../../styles/variables" as vars;

.submission-row {
  #groups-warning {
    text-align: center;
    padding: 1rem;
    align-self: center;
    flex-grow: 1;

    h1 {
      margin-bottom: 2rem;
    }
  }

  .submission-container {
    @extend %flex-col;
    justify-content: flex-start;
    // align-items: flex-start;
    flex-wrap: wrap;
    padding: 4rem 6rem;
    flex-grow: 1;

    .breadcrumb-wrapper {
      margin-bottom: 3rem;
    }

    .submission-menu {
      @extend %flex-col;
      animation: fadeIn 0.2s;
      flex-grow: 1;
      max-width: 1000px;

      h1 {
        color: vars.$navbar-color;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .file-drop-zone,
      .link-input-wrapper,
      .rich-editor {
        margin-bottom: 1rem;
      }

      .submission-wrapper {
        flex-grow: 1;
        align-self: stretch;

        .document-interface {
          flex-grow: 1;
          justify-content: flex-end;
          align-items: flex-start;

          .link-view-card {
            flex-grow: 0 !important;
          }
        }

        .view-ctrls {
          @extend %flex-row;
          padding: 1rem 0;

          div,
          button,
          a {
            margin-right: 1rem;

            &:last-child {
              margin: 0;
            }
          }

          i {
            font-size: vars.$font-size-md;
          }
        }
      }

      .buttons-wrapper {
        button {
          margin-right: 1rem;

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }

    .choose-btn-row {
      button {
        margin-right: 1rem;

        &:last-child {
          margin: 0;
        }

        i {
          margin-right: 0.5rem;
          vertical-align: middle;
        }
      }
    }
  }
}

#instructor-submission-menu {
  justify-content: center;
  align-items: center;
  padding: 1rem;

  #menu-wrapper {
    flex-direction: column;
    display: inline-flex;
    flex-grow: 0;
    max-width: 100vw;
    margin-bottom: 4rem;
  }

  .table-wrapper {
    overflow: auto;
    margin-bottom: 1rem;

    table {
      font-size: vars.$font-size-xxs !important;
    }
  }
}

@include pres.bp-laptop-sm {
  .submission-row {
    flex-direction: column;
    align-items: stretch !important;
  }
}

@include pres.bp-tablet-lg {
  .submission-row {
    .submission-container {
      padding: 2rem 3rem;
    }

    .breadcrumb-wrapper {
      margin-bottom: 1rem !important;
    }
  }
}

@include pres.bp-tablet-sm {
  .submission-row {
    .submission-container {
      padding: 2rem 1rem 8rem 1rem;
    }

    .text-submission-wrapper {
      min-width: auto !important;
    }

    .file-drop-zone {
      padding: 2rem !important;
      width: auto;
    }

    .link-input-wrapper {
      width: auto;
    }
  }
}

@include pres.bp-mobile-lg {
  .submission-row {
    .rich-read-only {
      padding: 0 !important;
    }

    .link-view-card {
      .flex-row {
        flex-wrap: wrap;
        justify-content: center;
      }

      a.link-view {
        min-width: 0 !important;
        margin-bottom: 0.5rem;
      }
    }

    .submission-menu > .submission-wrapper > .document-interface {
      align-items: center !important;
    }
  }

  #instructor-submission-menu {
    #menu-wrapper {
      padding: 1rem;
    }

    table {
      th,
      td {
        padding: 0.5rem;
      }
    }
  }
}
