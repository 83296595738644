@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.tabs,
.tabs-mini,
.tabs-mini-2,
.scrolling-tabs {
  @extend %flex-col;
  overflow: hidden;

  .tablist {
    .tab {
      @extend %default-btn;
      display: inline-block;
      margin-right: 1px;
      padding: 1rem;
      color: vars.$navbar-color !important;
      font-size: vars.$font-size-xs;
      line-height: vars.$font-size-xs;
      font-weight: bold;
      text-decoration: none;
      border-radius: 2px 2px 0 0;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0 !important;
      }
    }

    .tab[aria-selected='true'],
    .tab.selected {
      background-color: #f0f0f0;
      color: #006fb8 !important;
      box-shadow: inset 0 -0.25rem #0099ff;
    }
  }

  .tabpanel {
    overflow: auto;
    flex-grow: 1;
  }
}

.tabs-mini {
  .tablist {
    .tab {
      font-size: vars.$font-size-xs;
    }
  }
}

.tabs-mini-2 {
  .tablist {
    @extend %flex-row;
    justify-content: center;
    margin-bottom: 1rem;

    .tab {
      font-weight: bold;
      padding: 0.5rem 1.6rem;
      margin: 0 0.5rem;
      border-bottom: 2px solid vars.$border-soft-color;
    }

    .tab[aria-selected='true'],
    .tab.selected {
      border-color: vars.$primary-color-2;
      background-color: #f0f0f0 !important;
      color: vars.$primary-color-2 !important;
      box-shadow: none;
    }
  }
}

.scrolling-tabs {
  max-height: calc(100vh - 56px);
  overflow-y: auto;
  padding: 0 2rem 2rem 2rem;

  .tablist {
    position: sticky;
    top: 0;
    z-index: 5000;
    background-color: #f1f1f1a0;

    @include pres.bp-height {
      position: relative !important;
    }

    .tab:hover {
      background-color: #ebebeba0 !important;
    }
  }

  .tabpanel {
    overflow: visible;
    padding: 1rem;

    &:nth-child(odd) {
      background-color: darken(vars.$background-color, 2%);
    }

    h2 {
      align-self: stretch;
      padding-block-end: 0.5rem;
      border-bottom: 1px solid vars.$border-soft-color;
    }
  }
}

@include pres.bp-tablet-lg {
  .tabs > .tablist {
    .tab {
      font-size: vars.$font-size-xs;
    }
  }
}
