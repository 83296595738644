@use "../../../styles/presets" as pres;
@use "../../../styles/variables" as vars;

.benchmark-column {
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;

  .review-submission-window,
  #benchmark-menu {
    width: 512px;
  }

  #benchmark-menu {
    margin: 2rem 0;
  }
}

.benchmark-row {
  flex-direction: row !important;
  align-items: stretch !important;
  justify-content: center !important;

  .review-submission-window,
  #benchmark-menu {
    flex: 0 1 50%;
    margin: 0 1rem;
    @include pres.restrict-to-view-height(3rem);
  }

  .review-submission-window {
    max-width: 600px;
  }

  #benchmark-menu {
    max-width: 512px;
  }
}

#benchmark-page {
  padding: 1rem;

  #benchmark-menu {
    @extend %flex-col;
    background-color: white;
    border-radius: 2rem;
    padding: 2rem;
    overflow: hidden;

    h1 {
      text-align: center;
      font-size: vars.$font-size-sm;
    }

    .tabs {
      @extend %flex-col;
      flex-grow: 1;

      .tabpanel {
        padding-top: 1rem;
        flex-grow: 1;
      }
    }

    #grade-tab {
      @extend %flex-col-ac;
      justify-content: space-between;

      #grader-wrapper {
        @extend %flex-col-ac;
      }
    }

    #rubric-tab {
      overflow: auto;

      .read-only-rubric {
        padding: 0.5rem;

        .accordion-contents {
          padding: 0 !important;
          overflow: auto;
        }
      }
    }

    .ctrls {
      @extend %flex-row-ac;
      justify-content: space-between;
      padding-top: 1rem;
      align-self: stretch;

      .back-btn {
        order: 0;
      }
      .next-btn {
        order: 2;
      }

      #mid-ctrl {
        @extend %flex-col-ac;
        margin: 0 1rem;
        order: 1;

        .non-linear-pagination {
          .page-icon {
            @include pres.width-height(0.5rem);
            display: inline-block;
            border-radius: 0.5rem;
            background-color: #b8b8b8;
            margin: 2px;
            transition: box-shadow 0.2s;
          }

          .complete {
            background-color: #58cf7c !important;
          }

          .current {
            box-shadow: inset 0px 0px 0px 1px #58cf7c, inset 0px 0px 0px 2px white;
          }

          .incomplete-current {
            box-shadow: inset 0px 0px 0px 1px #b8b8b8, inset 0px 0px 0px 2px white;
          }
        }

        #finish-btn {
          border-radius: 1rem;
          padding: 0.5rem 1rem;

          #finish-txt {
            letter-spacing: 0.4rem;
            padding-left: 0.4rem;
          }

          span#progress {
            text-transform: none;
            font-size: 12px;
            letter-spacing: normal;
            font-weight: bold;
          }
        }
      }
    }
  }

  #graph-menu {
    flex-direction: column;
    flex-grow: 0;
    padding: 1rem 2rem 2rem 2rem;
    align-self: center;

    h1 {
      font-size: vars.$font-size-sm;
    }

    #graph-wrapper {
      @include pres.width-height(600px);
    }

    .ctrls {
      @extend %flex-row;
      justify-content: space-between;
      margin-top: 3rem;
    }
  }

  #tutorial {
    @include pres.restrict-to-view-width(1rem);
    flex-direction: column;
    flex: 0 0 auto;
    animation: fadeIn 1s;
    width: 800px;
    align-self: center;

    h1 {
      text-align: center;
      font-size: vars.$font-size-sm;
    }

    h2 {
      font-size: vars.$font-size-xs;
    }

    p {
      margin: 1rem 0;
      line-height: 2rem;
    }

    button {
      margin-top: 1rem;
      align-self: center;
    }
  }
}

@include pres.bp-laptop-md {
  .benchmark-row {
    .review-submission-window,
    #benchmark-menu {
      max-width: calc(50vw - 3rem);
    }
  }
}

@include pres.bp-laptop-sm {
  .benchmark-row > #benchmark-menu {
    .ctrls {
      flex-wrap: wrap;

      #mid-ctrl {
        order: -1;
        flex-basis: 100%;
      }
    }
  }

  #benchmark-page > #graph-menu > #graph-wrapper {
    @include pres.width-height(500px);
  }
}

@include pres.bp-tablet-lg {
  #benchmark-page > #graph-menu > #graph-wrapper {
    @include pres.width-height(400px);
  }
}

@include pres.bp-tablet-sm {
  .benchmark-column {
    .review-submission-window,
    #benchmark-menu {
      @include pres.restrict-to-view-width(2rem);
    }

    #benchmark-menu {
      margin-top: 1rem !important;
      padding: 1rem !important;
      border-radius: 1rem !important;

      .ctrls > #mid-ctrl {
        margin: 0 0.5rem !important;
      }
    }
  }

  #benchmark-page > #graph-menu {
    #graph-wrapper {
      @include pres.width-height(280px);
    }

    .ctrls {
      margin-top: 4rem;
      flex-direction: column;

      button {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@include pres.bp-mobile-lg {
  .benchmark-row > #graph-menu {
    padding: 1rem !important;
  }

  .benchmark-column {
    #benchmark-menu {
      .ctrls {
        flex-wrap: wrap;

        #mid-ctrl {
          order: -1;
          flex-basis: 100%;
        }
      }
    }
  }
}
