@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.calendar {
  border: 1px solid #e5e5e5;
  border-radius: 1rem 1rem 0 0;

  .ctrls {
    @extend %flex-row-ac;
    justify-content: space-between;
    padding: 1rem 3rem;
    width: 100;
  }

  table {
    border-collapse: collapse;
    color: #6e6e6e;

    th,
    td {
      @include pres.width-height(3rem);
      padding: 0;
      font-weight: normal;
      text-align: center;
      user-select: none;
    }

    td {
      cursor: pointer;
      position: relative;

      &:hover,
      &:focus,
      &:focus-visible {
        color: black;
        background-color: rgba($color: #e5e5e5, $alpha: 0.3);

        &::after {
          content: '';
          @extend %absolute-and-fill-space;
          z-index: 8000;
          box-shadow: inset 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
        }
      }

      &:focus-visible {
        &::after {
          @extend %focus-visible-style;
        }
      }

      span {
        position: relative;
        z-index: 9000;
        text-shadow: 2px 2px 4px #ffffff, -2px 2px 4px #ffffff, -2px -2px 4px #ffffff, 2px -2px 4px #ffffff;
      }

      .pattern1,
      .pattern2 {
        @extend %absolute-and-fill-space;
      }
    }

    td.past,
    td.future {
      opacity: 0.3;
    }

    td.today {
      box-shadow: inset 0 0 0 0.25rem #6e6e6eaf;
    }

    thead tr th {
      border: none;
    }
  }
}
