@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

fieldset.filter-tab {
  @extend %flex-row-jc-ac;
  flex-wrap: wrap;
  margin: 0;
  vertical-align: middle;

  & legend.filter-label,
  & .filter-btn {
    margin: 0 0.5rem 0.5rem 0;
  }

  legend.filter-label {
    margin-right: 1rem;
    display: inline;
    float: left;
    vertical-align: middle;
  }

  .filter-btn {
    position: relative;

    label {
      vertical-align: middle;

      i {
        font-size: vars.$font-size-xs;
        margin: 0 0.5rem 0 0;
      }
    }

    input[type='checkbox'],
    input[type='radio'] {
      @include pres.width-height(100%);
      opacity: 0;
      position: absolute;
      cursor: pointer;

      + label {
        @extend %text-break-to-ellipsis;
        max-width: 12rem;
        display: inline-block;
        border: 1px solid #d9d9d9;
        border-radius: 10rem;
        padding: 0.25rem 1rem;
        margin: 0;
        transition: box-shadow 0.5s;
      }

      &:hover {
        + label {
          border-color: darken(vars.$pale-text-color, 5%);
          background-color: #f6f6f6;
        }
      }

      &:focus-visible + label {
        @extend %focus-visible-style;
      }

      &:checked {
        + label {
          background-color: vars.$primary-color-2;
          border-color: vars.$primary-color-2;
          transition: background-color 0.1s;
          color: white;

          &:hover {
            border-color: vars.$primary-text-color !important;
          }
        }
      }
    }
  }
}
