@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.review-dialogue,
.feedback-dialogue {
  width: 100%;
  padding: 1rem 0;

  &.teacher-view {
    blockquote {
      &.received {
        margin-left: 0 !important;
      }

      &::before {
        content: none !important;
      }
    }
  }
}

.review-comment-dialogue-wrapper,
.feedback-comment-dialogue-wrapper {
  @extend %flex-col;
  position: relative;

  blockquote {
    background-color: #e5e5e5;
    border: 1px solid vars.$background-color;
    margin: 0;
    border-radius: 0.5rem;
    position: relative;
    display: inline-block;

    &::after {
      content: none;
    }

    .reaction {
      background-color: vars.$primary-color-2;
      border-radius: 1rem;
      display: inline-block;
      padding: 0.5rem 1rem;
      position: absolute;
      top: calc(100% - 0.5rem);
      right: 0;
    }
  }

  blockquote.received {
    margin: 0 3rem;
    align-self: flex-start;
  }

  blockquote.sent {
    background-color: vars.$primary-color-2;
    color: white;
    border: 1px solid vars.$background-color;
    margin: 0 0 0 6rem;
    border-radius: 0.5rem;
    position: relative;
    display: inline-block;
    align-self: flex-end;

    &::before,
    &::after {
      content: none !important;
    }

    .reaction {
      background-color: vars.$background-color;
    }
  }

  &:first-child:not(:last-child) blockquote {
    border-radius: 1rem 1rem 0.5rem 0.5rem;
  }

  &:last-child:not(:first-child) blockquote {
    border-radius: 0.5rem 0.5rem 1rem 1rem;
  }

  &:only-child blockquote {
    border-radius: 1rem;
  }

  &:last-child blockquote::before {
    @include pres.width-height(2rem);
    content: 'person_outline';
    font-family: 'Material Icons';
    position: absolute;
    right: calc(100% + 0.5rem);
    bottom: 0;
    color: #929da6;
    font-size: 25px;
    line-height: 2rem;
    background-color: #e5e5e5;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #929da6;
  }

  .ctrls {
    @extend %flex-col-ac;
    position: absolute;
    top: 0;
    right: 0;

    .report-btn {
      @extend %default-btn;
      margin: 0 !important;
      color: vars.$navbar-color;
      font-weight: bold;
      padding: 0.5rem 0.75rem;
      text-decoration: none;
      display: block;
    }
  }
}
