@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.avatar {
  @extend %flex-row-jc-ac;
  @include pres.width-height(2rem);
  display: inline-flex;
  padding: 0.25rem;
  border-radius: 50%;
  background-color: #e5860b;
  color: white;
  flex: 0 0 auto;
  user-select: none;
  font-weight: normal !important;
}
