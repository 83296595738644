@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.file-drop-zone {
  @extend %flex-col-ac;
  @include pres.panel-padding-radius(2rem 6rem, 0.5rem);
  background-color: white;
  margin-bottom: 1rem;
  border: 2px dashed #b4b4b4;
  width: 480px;

  i {
    font-size: 105px;
    color: vars.$navbar-color;
  }

  p,
  label {
    margin: inherit !important;
  }

  p {
    color: #6d6d6d;
    text-align: center;

    &:nth-child(3) {
      @extend %text-all-caps;
      color: #acacac;
    }
  }

  .upload-btn-wrapper {
    input[type='file'] {
      @extend %absolute-and-fill-space;
      @include pres.width-height(100%);
      opacity: 0;
      overflow: hidden;
      z-index: -1;

      &:focus-visible + label {
        @extend %focus-visible-style;
      }
    }

    label {
      cursor: pointer;
      position: relative;
    }
  }

  .file-name-preview {
    text-align: center;
  }
}

.dragging {
  border: 2px solid #6ddcfd;
  background-color: #cdf3ff;

  i {
    animation: bounce 1.2s infinite;
  }
}
