@use "../../styles/presets" as pres;
@use "../../styles/variables" as vars;

.tutorial-modal-checkbox-wrapper {
  text-align: center;

  input[type='checkbox'] {
    margin-right: 0.5rem;
  }

  label {
    margin: 1rem 0 0 0 !important;
  }

  input,
  label {
    cursor: pointer;
  }
}

#student-assignment-tutorial,
#comment-tutorial,
#rating-tutorial,
#student-results-tutorial {
  max-width: 480px;
  font-size: vars.$font-size-xxs;

  p {
    margin: 0 0 1rem 0;
  }
}

#student-assignment-tutorial {
  ol {
    list-style-type: none;
    margin-bottom: 1rem;

    li {
      margin-bottom: 0.5rem;
      vertical-align: middle;

      .assignment-phase-icon {
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }
  }
}
