@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.range-slider {
  position: relative;

  svg.slider-graphics {
    overflow: visible;
    position: relative;
    z-index: 10;
    pointer-events: none;

    circle {
      cursor: pointer;
    }

    text {
      user-select: none;
      pointer-events: none;
    }
  }

  input[type='range'] {
    @extend %absolute-and-fill-space;
    @include pres.width-height(100%);
    z-index: 0;
    opacity: 0;
    cursor: pointer;
  }
}
