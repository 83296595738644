@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

button.add-button {
  @extend %flex-row-jc-ac;
  @include pres.width-height(64px);
  background-color: vars.$primary-color-2;
  border: 1px solid vars.$primary-color-2;
  border-radius: 50%;
  box-shadow: 0 3px 6px 4px #0000002d;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 200;
  transition: border 0.2s, width 0.2s, height 0.2s, margin 0.2s;

  i {
    color: white;
    font-size: 48px;
    transition: transform 0.2s;
  }
}

button.add-button.focus {
  animation: pulse 2s infinite;
}

button.button-cancel {
  @include pres.width-height(56px);
  border: 0.25rem solid vars.$cancel-color;
  background-color: white;
  margin: 0.25rem;
  transition: border 0.2s, width 0.2s, height 0.2s, margin 0.2s;

  i {
    color: vars.$cancel-color;
    transform: rotate(45deg);
    transition: transform 0.2s;
  }
}

.shade-overlay {
  @include pres.inset(0, 0, 0, 0);
  padding-top: calc(#{vars.$navbar-height} * 2);
  background-color: #00000075;
  position: fixed;
  animation: fadeIn 0.5s;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
}

.actions-container {
  @extend %flex-col;
  min-height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 2rem 6rem 0;
  animation: grow 0.5s;

  @keyframes grow {
    0% {
      padding: 0 2rem 0rem 0;
    }
    100% {
      padding: 0 2rem 6rem 0;
    }
  }

  button.action-button-wrapper {
    @extend %flex-row-ac;
    @extend %default-btn;
    margin-bottom: 2rem;
    padding: 0;
    animation: slideInUp 0.5s;

    .action-lbl {
      background-color: white;
      border-radius: 10rem;
      padding: 0.75rem 4rem 0.75rem 1.6rem;
      margin: 0 1rem 0 0;
      font-weight: bold;
      color: vars.$icon-color;
      box-shadow: 0 3px 6px 4px #0000002d;
      animation: slideInRight 0.5s, labelAppear 0.5s;
      overflow: hidden;
      pointer-events: none;

      @keyframes labelAppear {
        0% {
          clip-path: inset(0 100% 0 0);
        }
        100% {
          clip-path: inset(-25% 0 -25% -25%);
        }
      }
    }

    i {
      background-color: vars.$primary-color-2;
      padding: 1rem;
      border-radius: 50%;
      font-size: vars.$icon-size;
      color: white;
      box-shadow: 0 3px 6px 4px #0000002d;
      margin-left: -2em;
      z-index: 100;
      pointer-events: none;
    }
  }
}

@include pres.bp-laptop-sm {
  button.add-button {
    bottom: calc(#{vars.$tab-bar-height} + 2em);
    right: 2em;
  }

  .actions-container {
    padding: 0 2em calc(#{vars.$tab-bar-height} + 7em) 0;
  }

  @keyframes grow {
    0% {
      height: 0%;
      padding: 0 2em 0 0;
    }
    100% {
      height: 100%;
      padding: 0 2em calc(#{vars.$tab-bar-height} + 7em) 0;
    }
  }
}
