@use '../../styles/presets' as pres;

#review-page {
  .col {
    max-width: 100%;

    .review-submission-window,
    #review-form-container {
      max-width: 100%;
    }
  }

  &.row {
    padding: 1rem 0.5rem;

    .col {
      max-width: 50vw;
      padding: 0 0.5rem;
    }
  }

  &.column {
    flex-direction: column !important;
    align-items: center;
    padding: 0.25rem 0;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .col {
      padding: 0.25rem 0.5rem !important;
    }

    #submission-column,
    #review-column {
      padding: 0.5rem;
      align-items: flex-start;
      flex: 0 0 auto;
    }
  }

  #submission-column,
  #review-column {
    flex-basis: 50%;
  }

  #submission-column {
    .review-submission-window {
      flex-basis: 100%;
    }
  }

  #review-column {
    #review-form-container {
      padding: 0 2rem !important;
    }
  }

  #review-menu {
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem 0 0;
    padding: 3rem 2rem;
    animation: fadeIn 1s 1s backwards;
    flex-grow: 0;

    .option-btn {
      margin: 0 0 2rem 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}

@include pres.bp-laptop-lg {
  #review-page {
    #review-form > .form-footer {
      #form-btn-previous,
      #form-btn-next,
      #form-btn-submit {
        flex: 0 0 calc(100% * (1 / 3));
      }

      #form-progress-bar {
        flex: 1 1 100%;
        order: 99;
        margin: 1rem 2rem 0 2rem;
        height: 3rem;
      }
    }
  }
}

@include pres.bp-tablet-lg {
  #review-column {
    padding-bottom: 2rem;
  }
}

@include pres.bp-mobile-lg {
  #review-column {
    padding-bottom: 1em;
  }

  #review-menu {
    padding: 1.2rem 1.6rem;

    button.option-btn,
    a.option-btn {
      margin: 0 1rem 0 0 !important;

      &:last-child {
        margin: 0 !important;
      }
    }
  }
}
