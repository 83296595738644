@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.submission-wrapper {
  @extend %flex-col-ac;
  width: 100%;

  .document-interface {
    @extend %flex-col-jc-ac;
    @include pres.width-height(100%);

    .pdf-wrapper {
      @include pres.width-height(100%);
      transition: opacity 1s 0.2s;
    }

    .misc-file-submission-viewer {
      @extend %flex-row-jc;
      flex-wrap: wrap;
      @include pres.panel-padding-radius(1rem, 10rem);

      h2 {
        flex-basis: 100%;
        text-align: center;
      }

      i {
        font-size: vars.$icon-size;
        color: vars.$navbar-color;
        margin-right: 0.5rem;
      }
    }

    .link-view-card {
      a.link-view {
        @extend %text-break-to-ellipsis;
        flex-grow: 1;
        color: vars.$pale-text-color !important;
        min-width: 16rem;
      }
    }

    .text-submission-wrapper {
      max-height: 560px;
      min-width: 480px;
      max-width: 640px;
      flex-grow: 0;

      h2 {
        text-align: center;
        color: vars.$navbar-color;
      }

      .rich-read-only {
        width: 100%;
        padding-right: 2rem;
        overflow-y: scroll;
      }
    }
  }

  .video-file-submission-viewer {
    .processing-video {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgb(216, 216, 216);
      padding: 2rem 3rem;
      border-radius: 0.25rem;
      aspect-ratio: 16 / 9;

      &.error {
        background-color: #ffd2d2 !important;
        color: darken(#ffd2d2, 80%);
      }

      .title {
        @extend %flex-row-jc-ac;
        margin-bottom: 1rem;

        b {
          margin-left: 0.5rem;
          font-size: vars.$font-size-sm;
        }
      }

      p {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.review-submission-window {
  @extend %flex-col;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;

  .main-row {
    @extend %flex-row-ac;
    justify-content: space-between;

    .details {
      flex-shrink: 2;
      margin-right: 1rem;

      p {
        margin-bottom: 0.5rem;
      }

      p.type {
        text-transform: capitalize;
      }
    }

    .ctrls {
      @extend %flex-row;
      flex-shrink: 1;
      flex-wrap: wrap;
      justify-content: flex-end;
      min-height: 3.5rem;

      button#collapse-btn {
        width: 3.5rem;
      }

      button,
      a {
        display: inline-block;
      }

      .icon-btns {
        display: inline-block;
        flex-shrink: 0;
        margin: 0.25rem 0 0.25rem 0.5rem;

        button,
        a {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .submission-display-wrapper {
    @extend %flex-col-jc-ac;
    flex-grow: 1;
    overflow: hidden;

    .file-preview,
    .link-preview {
      a {
        vertical-align: middle;

        i {
          margin-right: 1rem;
        }

        i,
        span {
          vertical-align: middle;
        }
      }
    }

    .file-preview {
      .info {
        @extend %flex-row;
        justify-content: space-between;
        margin-bottom: 1rem;
      }
    }

    .file-viewer,
    .text-viewer {
      @include pres.width-height(100%);
    }

    .file-viewer {
      @extend %flex-col-jc-ac;

      .pdf-wrapper {
        height: 100%;
        align-self: stretch;
      }

      .pdf-unsupported-text {
        padding: 1rem;

        a {
          font-weight: bold;
        }
      }
    }

    .loading {
      .pdf-wrapper {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    }

    .text-viewer {
      @include pres.restrict-to-view-height(6rem);
      padding: 0.5rem;
      border: 1px solid vars.$border-soft-color;
      border-radius: 0.25rem;
      overflow: auto;
    }
  }
}

.video-file-submission-viewer {
  @include pres.width-height(100%);
}

@include pres.bp-mobile-lg {
  .review-submission-window {
    .main-row {
      flex-direction: column;

      .details {
        align-self: flex-start;
      }
    }

    .submission-display-wrapper > .text-viewer {
      @include pres.restrict-to-view-height(10rem);
    }
  }
}
