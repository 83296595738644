@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.star-rating {
  .stars {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
