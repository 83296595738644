@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;

#evaluation-page {
  @extend %flex-row;
  gap: 1rem;
  justify-content: center;

  .main-menu {
    @include pres.panel-padding-radius(1rem, 1rem);
    flex-grow: 1;
    max-width: 640px;
    margin: 1rem 0 4rem 0;

    h1 {
      font-size: vars.$font-size-md;
    }

    .top-info {
      @extend %flex-row;
      justify-content: space-between;
      align-items: flex-start;

      span.main-progress {
        @extend %flex-row-ac;
        font-weight: bold;

        .progress-ring {
          margin-right: 0.5rem;
        }
      }

      #return-btn {
        margin: -1rem -1rem 0 0;
        border-radius: 0 1rem 0 0.5rem;
      }
    }

    .alert-bar {
      margin-bottom: 1rem;
    }

    .eval-category-accordion {
      .accordion-heading {
        background-color: #f6f6f6;
        padding: 0.5rem;

        .accordion-toggle {
          margin-right: 0.5rem;
        }

        h2 {
          font-size: 24px;
        }

        .custom-heading-content .progress {
          @extend %flex-row-ac;
          @include pres.panel-padding-radius(0.5rem 1rem, 2rem);
          font-weight: bold;
          gap: 0.5rem;
        }
      }

      .accordion-contents {
        background-color: #fcfcfc;
      }

      .accordion-heading,
      .accordion-contents {
        box-shadow: none;
      }
    }

    .score-entry {
      padding: 0.5rem;
      border-radius: 2px;
      box-shadow: 0 3px 6px 4px #0000000f;
      margin-right: 2.5rem;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      .main-partition {
        @extend %flex-row-ac;
        justify-content: space-between;
        flex-grow: 1;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        border-right: 1px solid vars.$border-soft-color;
      }

      .unranked {
        opacity: 0.2;
      }

      .score {
        font-weight: bold;
      }

      .status-wrapper {
        i {
          color: vars.$pale-text-color;
          margin: 0 0 0 0.5rem !important;
        }
      }

      .progress-ring {
        position: absolute;
        right: -2.5rem;
      }
    }
  }
}

#evaluation-page,
.eval-context {
  // padding: 4rem;
  // justify-content: space-between;
  // flex-wrap: wrap;

  h1 {
    font-size: vars.$font-size-sm;
  }

  h2#num-unranked {
    font-size: vars.$font-size-xs;
    text-align: center;
    color: vars.$pale-text-color;
    font-weight: normal;
  }

  .rank {
    @include pres.width-height(1.5rem);
    display: inline-block;
    border-radius: 0.5rem;
    font-size: vars.$font-size-xxs;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }

  .unranked {
    color: #757575;
  }

  #rank-1 {
    background-color: #fff787;
  }

  #rank-2 {
    background-color: #e9e9e9;
  }

  #rank-3 {
    background-color: #ffe387;
  }

  .eval-scoreboard-wrapper {
    #back-btn {
      margin-bottom: 1rem;
      vertical-align: middle;

      i {
        margin: 0 -0.25rem;
      }

      i,
      span {
        vertical-align: middle;
      }
    }
  }

  .score-entry {
    @extend %flex-row-ac;
    @include pres.panel-padding-radius(1rem, 0.5rem);
    justify-content: space-between;
    min-width: 360px;
    user-select: none;
    position: relative;

    .rank,
    .avatar,
    .name {
      margin-right: 1rem;
    }

    .name {
      font-weight: bold;
      display: inline-block;
      word-break: break-word;
    }

    .score {
      white-space: nowrap;

      .points {
        font-size: vars.$font-size-xxs;
      }
    }

    .scoreless {
      color: vars.$pale-text-color;
    }

    .action-icon {
      @include pres.width-height(2rem);
      background-color: vars.$primary-color-2;
      color: white;
      border-radius: 50%;
      text-align: center;
      line-height: 2rem;
    }

    .done-icon {
      position: absolute;
      left: calc(100% + 0.5rem);
      color: #3e9b22;
    }
  }

  .eval-scoreboard {
    .score-entry,
    .category-entry {
      transition: transform 0.2s;
      cursor: pointer;

      &:hover {
        transform: translateX(1rem);
      }
    }
  }

  .live-eval-scoreboard.off .score-entry {
    opacity: 0.5;
  }

  .eval-scoreboard,
  .live-eval-scoreboard {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;

    ul {
      @extend %default-list;
    }

    .score-entry {
      position: relative;
      margin-bottom: 0.5rem;

      .right-bubble {
        position: absolute;
        left: 100%;
      }

      .diff {
        @include pres.width-height(3rem, 2rem);
        @include pres.panel-padding-radius(0.25rem, 0.75rem);
        margin-left: 1rem;
        text-align: center;
        color: #2e74a3;

        i {
          font-size: vars.$font-size-xs;
          margin-left: -0.2rem;
          margin-right: 0.2rem;
          vertical-align: -1px;
        }
      }
    }

    .score-entry.current {
      border: 3px solid #2e74a3;
      padding: calc(1rem - 3px);

      .diff {
        margin-left: calc(1rem + 3px);
        background-color: #2e74a3;
        color: white;
      }
    }
  }

  #eval-preview {
    @extend %flex-col-ac;
    @include pres.panel-padding-radius(2rem, 2rem);
    background-color: white;
    max-width: 640px;
    flex: 0 0 50%;
    align-self: center;

    .title-row {
      margin-bottom: 1rem;

      .avatar {
        margin-right: 1rem;
      }

      h2 {
        display: inline-block;
        margin: 0;
      }
    }

    #preview-body {
      padding: 0 1rem;
      align-self: stretch;
    }

    .rank-row {
      text-align: center;

      p {
        height: 2rem;
        line-height: 2rem;
      }

      .rank {
        @include pres.width-height(2rem);
        font-size: vars.$font-size-xs;
        line-height: 2rem;
        margin-left: 0.5rem;
      }
    }
  }

  #peer-select-btn {
    margin-bottom: 1rem;
  }

  #category-wrapper {
    margin-bottom: 1rem;
    align-self: flex-start;

    ul#category-entry-nav {
      @extend %default-list;
    }

    a.category-entry {
      @extend %flex-row;
      @include pres.panel-padding-radius(0, 0.5rem);
      @include pres.width-height(320px, 4.5rem);
      margin-bottom: 0.5rem;
      width: 320px;
      color: inherit;
      text-decoration: none;

      .details-wrapper {
        display: inline-flex;
        justify-content: space-between;
        padding: 0 1rem;
        margin: 0.5rem;
        flex: 1 0 auto;

        h2,
        .stats {
          display: inline-block;
          text-align: end;

          i.check-icon {
            vertical-align: sub;
            margin-left: 0.25rem;
            color: #55c92d;
            line-height: vars.$font-size-xs;
          }

          p {
            margin: 0;
            line-height: calc(3.5rem / 2);
            opacity: 0.8;
          }
        }

        h2 {
          font-size: vars.$font-size-xs;
        }
      }

      i.arrow-icon {
        height: 100%;
        color: #748490;
        border-left: 1px solid vars.$border-soft-color;
        vertical-align: middle;
        line-height: 4.5rem;
      }
    }
  }

  #eval-form-container {
    flex-grow: 1;
    max-width: 640px;
    margin-top: 1rem;

    .peer-id-bar {
      @extend %flex-row;
      justify-content: space-between;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid vars.$border-soft-color;

      .avatar,
      h1 {
        margin-right: 1rem;
      }

      .avatar {
        display: inline-flex;
      }

      h1 {
        display: inline-block;
      }

      .badge {
        @extend %text-all-caps;
        letter-spacing: 2px;
        border-radius: 0.5rem;
        padding: 0.6rem;
        background-color: rgb(238, 238, 238);
        color: gray;
        align-self: center;
        font-size: vars.$font-size-xs !important;

        span {
          vertical-align: middle;
        }
      }
    }

    .alert-bar {
      margin-bottom: 1rem;

      u {
        margin: 0 1ch;
      }
    }
  }

  #peer-select-menu {
    @extend %flex-col-ac;
    flex: 1 0 auto;

    .ctrls {
      @extend %flex-row-ac;
      margin-bottom: 1rem;
      gap: 0.25rem;
    }

    #peer-select-list {
      max-height: calc(100vh - #{vars.$navbar-height} - 20rem);
      overflow: auto;
      padding: 0 1rem;

      .peer-entry {
        @extend %flex-row-ac;
        @include pres.panel-padding-radius(1rem, 10rem);
        margin-bottom: 0.5rem;
        justify-content: space-between;

        .avatar {
          margin-right: 1rem;
        }

        h2 {
          display: inline-block;
          font-size: vars.$font-size-xs;
          margin: 0 2rem 0 0;
        }
      }
    }
  }

  #mobile-open-scoreboard {
    display: none;
    margin-bottom: 1rem;
  }

  .comment-preview-container {
    @extend %flex-col;
    align-items: flex-end;
    width: 100%;
    padding: 1rem;
    max-height: 256px;
    overflow-y: auto;
  }
}

.eval-context {
  background-color: #f1f1f1;
  padding: 0.5rem;
  overflow: auto;
}

table.eval-rating-scores,
table#eval-comments {
  @extend %table-no-borders;
  min-width: 100%;
  margin-bottom: 1rem;

  tbody {
    th,
    td {
      padding: 0.5rem 1rem;
    }

    th {
      text-align: start;
      border-top-left-radius: 1.6rem;
      border-bottom-left-radius: 1.6rem;
    }

    td {
      text-align: end;
      color: #8d8d8d;
      border-top-right-radius: 1.6rem;
      border-bottom-right-radius: 1.6rem;
      white-space: nowrap;
    }

    tr#overall {
      background-color: #eeeeee;
    }
  }

  &.odd {
    tr:nth-child(even) {
      background-color: #f8f8f8;
    }
  }

  &.even {
    tr:nth-child(odd) {
      background-color: #f8f8f8;
    }
  }
}

#eval-points-allocation-form-container {
  max-width: 512px;
  margin: 1rem 0;

  .peer-form-header {
    h1 {
      font-size: vars.$font-size-xs;
    }

    p {
      margin: 0;
    }

    .alert-bar {
      margin-bottom: 0.5rem;
    }
  }

  .peer-form-body {
    font-size: vars.$font-size-xxs !important;
  }

  .allocate-points-wrapper {
    @extend %flex-col;
    align-items: stretch;
  }

  .allocation-status {
    @extend %flex-row;
    justify-content: space-between;
    align-items: baseline;
    background-color: #ffefd2;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    vertical-align: middle;

    span {
      margin: 0 0.5rem;
    }

    i {
      font-size: 20px;
      line-height: 20px;
      vertical-align: middle;
      color: vars.$success-color;
    }

    &.done {
      background-color: #c5ffc4;
    }
  }

  table.allocate-points-table {
    width: 100%;
    font-weight: bold !important;

    label {
      margin: 0;
    }

    td.name {
      text-align: left;
    }

    input[type='number'] {
      background-color: transparent !important;
      border-radius: 2px;
      width: 3rem !important;
      padding: 0;
      text-align: right;
      font-weight: bold;
      text-decoration: underline;
      margin-right: 0.125rem;
      background-color: white !important;
      border: 1px solid rgba(128, 128, 128, 0.1);
    }

    tr:nth-child(even) input[type='number'] {
      background-color: rgb(245, 245, 245) !important;
    }
  }

  .score-entry {
    .details-wrapper * {
      vertical-align: middle;
    }

    .status-wrapper {
      font-weight: bold;
    }
  }
}

@include pres.bp-laptop-sm {
  #evaluation-page {
    padding: 2rem;
  }
}

@include pres.bp-tablet-lg {
  #evaluation-page {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    #category-wrapper {
      align-self: auto;

      h1 {
        text-align: center;
      }
    }

    #eval-preview {
      align-self: stretch;
      max-width: none !important;
    }

    .live-eval-scoreboard {
      margin-bottom: 0;

      .score-entry {
        display: none;
      }

      .score-entry.current {
        display: flex !important;
      }
    }

    #mobile-open-scoreboard {
      display: block;
    }
  }
}

@include pres.bp-tablet-sm {
  #evaluation-page {
    padding: 1rem;
  }

  .eval-context {
    .live-eval-scoreboard {
      .score-entry,
      .category-entry {
        min-width: initial;
        transform: none !important;

        .avatar {
          display: none;
        }
      }
    }
  }
}

@include pres.bp-mobile-lg {
  #evaluation-page {
    padding: 0.5rem;
    align-items: stretch;

    .eval-scoreboard,
    .live-eval-scoreboard {
      .score-entry,
      .category-entry {
        min-width: initial;
        transform: none !important;

        .avatar {
          display: none;
        }
      }
    }

    #eval-preview {
      padding: 1rem;

      #preview-body {
        padding: 0;
      }
    }
  }
}
