@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;
@use '../../../../styles/animations';

.rating-card-lg {
  @extend %flex-col;
  margin-bottom: 0.5rem;

  .rating-entry-wrapper-lg {
    position: relative;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    input[type='radio'] {
      @include pres.width-height(100%);
      opacity: 0;
      position: absolute;
      cursor: pointer;

      &:hover + label {
        border-width: 2px !important;
        border-color: lighten(vars.$primary-text-color, 40%) !important;
        padding: calc(1em - 1px) calc(2em - 1px) !important;
        box-shadow: 0 0 2px 2px lighten(vars.$primary-text-color, 40%);
      }

      &:focus-visible + label {
        @extend %focus-visible-style;
      }

      &:checked {
        + label {
          background-color: vars.$primary-text-color;
          border-color: vars.$primary-text-color;
          transition: background-color 0.1s;
          color: white;

          &:hover {
            border-color: vars.$primary-text-color !important;
          }
        }
      }
    }

    label.rating-entry-lg {
      display: block;
      color: vars.$primary-text-color;
      border: 1px solid vars.$primary-text-color;
      border-radius: 5rem;
      padding: 1rem 2rem;
      text-align: left;
      background-color: transparent;
      transition: background-color 0.1s, box-shadow 0.1s;
      cursor: pointer;
      margin: 0;
      user-select: none;

      &:hover {
        border-width: 2px;
        border-color: darken(vars.$primary-text-color, 5%);
        padding: calc(1em - 1px) calc(2em - 1px);
      }
    }
  }
}

.rating-card-sm {
  @extend %flex-row;
  position: relative;
  justify-content: space-evenly;
  margin-bottom: 1.6rem;
  padding: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    top: 50%;
    width: 90%;
    background-color: vars.$primary-text-color;
  }

  .rating-card-select-transition-sm {
    @include pres.width-height(3rem);
    opacity: 0;
    position: absolute;
    background-color: vars.$primary-text-color;
    border-radius: 50%;
    z-index: 1;
    pointer-events: none;
    transition: transform 0.2s, height 0.2s, margin 0.2s, opacity 0.5s;
  }

  .rating-entry-wrapper-sm {
    @extend %flex-row-jc-ac;
    @include pres.width-height(3rem);
    margin-right: 2rem;

    &:nth-child(2)::after,
    &:nth-child(4)::after,
    &:last-child::after {
      font-size: 12px;
      text-align: center;
      position: absolute;
      top: 100%;
    }

    &:nth-child(2)::after {
      content: 'Least Helpful';
    }

    &:nth-child(4)::after {
      content: 'Average';
    }

    &:last-child {
      margin: 0;

      &::after {
        content: 'Most Helpful';
      }
    }

    input[type='radio'] {
      @include pres.width-height(3rem);
      opacity: 0;
      cursor: pointer;

      &::after {
        font-size: vars.$font-size-md;
        padding: 0.3rem;
      }

      &:hover ~ .rating-entry-radio-btn-sm {
        background-color: darken(vars.$white, 5%);
      }

      &:checked ~ .rating-entry-radio-btn-sm {
        background-color: vars.$primary-text-color;
        color: white;

        &::after {
          @include pres.width-height(0.5rem);
          content: '';
          position: absolute;
          opacity: 0;
          background-color: lighten(vars.$primary-text-color, 30%);
          border-radius: 50%;
          z-index: -1;
          animation: click-highlight 0.5s both;
        }
      }
    }

    .rating-entry-radio-btn-sm {
      @extend %flex-row-jc-ac;
      @include pres.width-height(1.5em);
      position: absolute;
      vertical-align: -50%;
      z-index: 10;
      user-select: none;
      pointer-events: none;
      border-radius: 50%;
      font-size: vars.$font-size-sm;
      border: 1px solid vars.$primary-text-color;
      background-color: vars.$white;
      color: vars.$primary-text-color;
    }
  }
}

.rating-label-supplement-container-sm {
  opacity: 0;
  position: absolute;
  width: 100%;
  bottom: 6.2rem;
  padding: 1rem;
  background-color: vars.$primary-text-color;
  color: white;
  border-radius: 1em;
  overflow-wrap: break-word;
  text-align: center;
  animation-duration: 0.5s;
  animation-delay: 0.5s;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid vars.$primary-text-color;
  }
}

@include pres.bp-tablet-sm {
  .rating-entry-wrapper-sm {
    margin-right: 0.5em !important;

    &:last-child {
      margin: 0 !important;
    }
  }
}
