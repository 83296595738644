@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

nav.peer-pagination {
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  background-color: transparent !important;

  li * {
    margin: 0.2rem;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      float: left;
      list-style-type: none;
    }
  }

  button {
    @extend %default-btn;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid vars.$primary-color-2;
    color: vars.$primary-color-2;
    background-color: white;
    border-radius: 10rem;
    text-decoration: none;
    text-align: center;

    &:hover {
      cursor: pointer;
      color: white;
      background-color: vars.$primary-color-2;
      transition: color 0.2s, background-color 0.2s;
    }

    &:disabled,
    &[aria-disabled='true'] {
      color: #c4c4c4;
      border-color: #c4c4c4;
      background-color: #ededed;
      pointer-events: none;
    }

    i {
      margin: 2px 0 0 0;
    }
  }

  button.selected {
    color: white;
    background-color: vars.$primary-color-2;

    &:disabled,
    &[aria-disabled='true'] {
      border-color: vars.$primary-color-2;
    }
  }

  select {
    color: vars.$primary-color-2;
    padding: 0.25rem;
    border: none;
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    text-align: center;

    &:hover {
      cursor: pointer;
      background-color: rgb(244, 244, 244);
    }

    &:focus {
      border-color: #aaa;
      background-color: rgb(244, 244, 244);
    }

    & option {
      font-weight: normal;
    }

    &:disabled,
    &[aria-disabled='true'] {
      color: graytext;
    }

    &:disabled:hover,
    &[aria-disabled='true'] {
      border-color: #aaa;
    }
  }
}
