@use "../../styles/presets" as pres;
@use "../../styles/variables" as vars;

#error-overlay {
  @extend %flex-row-jc-ac;
  @extend %absolute-and-fill-space;
  z-index: 10001;
  pointer-events: none;

  #error-alert {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    padding: 2rem;
    border-radius: 1rem;
    pointer-events: auto;
    user-select: none;
    opacity: 0;
    animation: fadeInAndOut 3s;

    @keyframes fadeInAndOut {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

#error-log-page {
  padding: 2rem;
  flex-direction: column;

  #back-btn {
    align-self: flex-start;
  }

  #error-log-container {
    .accordion-col {
      overflow: hidden;
    }

    pre {
      overflow: auto;
      background-color: rgb(238, 238, 238);
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
  }
}
