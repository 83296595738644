@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.notification {
  @extend %flex-row;
  padding: 0 0.5rem;

  .graphic {
    @include pres.width-height(32px);
    text-align: center;
    border: 1px solid vars.$border-soft-color;
    border-radius: 0.5rem;
    margin-right: 0.75rem;
    user-select: none;
    flex-shrink: 0;
    margin-top: 0.25rem;

    i {
      vertical-align: middle;
      line-height: 30px;
      font-size: 20px !important;
    }
  }

  .body {
    flex-grow: 1;
    user-select: none;
    position: relative;

    p {
      margin: 0;
    }

    p.title {
      font-weight: bold;
    }

    p.description {
      color: rgba(255, 255, 255, 0.7);

      .dot {
        margin: 0 2px;
        font-size: 16px;
        line-height: 14px;
        vertical-align: middle;
      }
    }

    .actions {
      @extend %flex-row;
      flex-wrap: wrap;
      letter-spacing: 0;
      margin-top: 0.25rem;

      .peer-button {
        flex-basis: 49%;
        flex-grow: 1;
        letter-spacing: 0 !important;
        padding: 0.25rem 0.5rem !important;

        &:nth-child(odd) {
          margin-right: 0.125rem;
        }

        &:nth-child(even) {
          margin-left: 0.125rem;
        }
      }

      :not(.button-alt) {
        color: vars.$navbar-color !important;
        background-color: white;

        &:hover {
          background-color: rgba(255, 255, 255, 0.852);
        }
      }
    }

    button.close {
      display: none;
    }
  }

  &.unread {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.dismissable {
    p.title,
    p.description {
      padding-right: 1.5rem;
    }

    button.close {
      @include pres.width-height(1.5rem);
      display: block;
      margin-right: -0.25rem;
      position: absolute;
      top: -0.5rem;
      right: 0rem;
      padding: 0 !important;

      i {
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
      }
    }
  }

  &.dismissed {
    animation: fadeOut 0.5s forwards;
  }
}

a.notification:hover,
a.notification:focus {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
