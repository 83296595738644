@use '../../styles/presets' as pres;

#reflection-page {
  @extend %flex-row-jc;
  // flex-wrap: wrap;
  padding: 1em;

  .rating-card {
    margin-top: 1em;
  }

  #reflection-panel-left {
    flex: 1 0 45%;
    padding-right: 2em;
  }
  #reflection-panel-right {
    flex: 1 0 55%;
  }

  .submission-display-wrapper {
    min-height: 100vh;

    .file-viewer {
      flex-grow: 1;
 
      .pdf-wrapper {
        flex-grow: 1;

        .pdfobject {
          flex-grow: 1;
        }
      }
    }
  }
}

@include pres.bp-laptop-md {
  #reflection-page {
    flex-direction: column;
  }
}
