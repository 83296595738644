@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

$timeline-width: 260px;

form.wizard {
  @extend %flex-row;
  background-color: white;
  border-radius: 2rem;
  align-items: stretch;
  max-width: 100%;
  margin: 2rem;

  .timeline {
    border-radius: 2rem 0 0 2rem;
    width: $timeline-width;
    min-width: $timeline-width;
    background-color: #f3fbff;
    padding: 1rem 2rem;
    position: relative;
    overflow: hidden;

    h1 {
      @extend %text-all-caps;
      font-weight: normal;
      color: white;
      font-size: vars.$font-size-sm;
      text-align: center;
      margin-bottom: 2rem;
      position: relative;
      z-index: 100;
    }

    .list-wrapper {
      list-style-type: none;
      position: relative;
      z-index: 100;
      font-size: vars.$font-size-sm;
      padding-inline-start: 2rem;

      button {
        @extend %default-btn;
        padding: 0;
        margin-bottom: 2rem;
        transition: color 0.5s;
        user-select: none;
        cursor: pointer;
      }

      button.past,
      button.current {
        color: white;
      }

      button.current {
        font-weight: bold;
      }
    }

    .back-progress-bar {
      @include pres.inset(0, 0, -4rem, 0);
      position: absolute;
      background-color: #2e74a3;
      border-radius: 0 0 4rem 4rem;
      z-index: 1;
      transition: bottom 0.5s;
    }
  }

  .content {
    @extend %flex-col;
    justify-content: space-between;
    padding: 2rem;
    flex-grow: 1;
    overflow: hidden;

    .form-content {
      @extend %flex-col;
      align-self: stretch;

      h2 {
        font-size: vars.$font-size-sm;
      }

      h3 {
        font-size: vars.$font-size-xs;
      }

      label,
      fieldset {
        font-size: vars.$font-size-xs;
        font-weight: bold;
      }

      input[type='checkbox'] {
        margin: 0 1rem;
        vertical-align: text-top;

        &:not(:disabled) {
          cursor: pointer;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .footer-ctrls {
      @extend %flex-row-ac;
      align-self: stretch;
      justify-content: space-between;
      padding: 2rem;

      .inner-pagination {
        @extend %flex-row-ac;

        .dot {
          @include pres.width-height(1rem);
          border-radius: 50%;
          background-color: #eeeeee;
          margin: 0.5rem;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .current {
          background-color: #6dd0ef;
        }
      }

      button > span.icon {
        display: none;
      }
    }
  }
}

@include pres.bp-tablet-lg {
  form.wizard {
    flex-direction: column !important;

    .timeline {
      width: auto !important;
      border-radius: 2rem 2rem 0 0 !important;
      background-color: #2e74a3 !important;

      h1 {
        margin-bottom: 1rem;
      }

      .list-wrapper {
        display: flex;
        justify-content: center;
        padding: 0;

        button {
          display: none;
          margin: 0;
        }

        button.current {
          display: list-item;
        }
      }

      .back-progress-bar {
        display: none;
      }
    }
  }
}

@include pres.bp-mobile-lg {
  form.wizard {
    .timeline {
      .list-wrapper button {
        font-size: vars.$font-size-xs !important;
      }

      h1 {
        font-size: vars.$font-size-xs !important;
      }
    }

    .content {
      padding: 1rem !important;

      .footer-ctrls {
        padding: 2rem 0 0 0 !important;

        button {
          span.text {
            display: none;
          }

          span.icon {
            display: inline-block !important;
          }
        }
      }
    }
  }
}
