@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.assignment-phase-icon {
  display: inline-block;
  border-radius: 50%;
  flex-shrink: 0;

  i {
    width: 100%;
    height: 100%;
    line-height: 150%;
    color: white;
    text-align: center;
    user-select: none;
  }

  &.submit,
  &.group-formation {
    background-color: vars.$submit-color;
  }

  &.review {
    background-color: vars.$review-color;
  }

  &.feedback {
    background-color: vars.$feedback-color;
  }

  &.evaluate {
    background-color: vars.$evaluate-color;
  }

  &.awaiting {
    background-color: vars.$navbar-color;
  }

  &.complete {
    background-color: vars.$success-color;
  }

  &.reflection {
    background-color: vars.$reflect-color;
  }
}
