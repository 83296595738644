@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

fieldset.star-rating-input {
  align-items: flex-start;

  legend {
    font-weight: bold;
    text-align: left !important;
  }

  .row-container {
    @extend %flex-row-ac;

    .fraction {
      font-size: vars.$font-size-xxs;
      font-weight: bold;
      width: 2rem;
    }

    p {
      margin: 0 0 0 1rem;
    }
  }

  .stars-wrapper {
    @extend %flex-row;

    background-color: #f8f8f8;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    height: calc(24px + 1rem);
  }

  .star {
    @include pres.width-height(24px);
    display: inline-block;
    position: relative;

    input[type='radio'] {
      @extend %absolute-and-fill-space;
      opacity: 0;
      cursor: pointer;
      z-index: 10;
    }

    label {
      @extend %absolute-and-fill-space;
      display: block;
      margin: 0;

      &::before,
      &::after {
        @extend %absolute-and-fill-space;
        content: 'star';
        font-family: 'Material Icons';
        color: #71818d;
        font-size: 24px;
        line-height: 24px;
      }

      &::after {
        color: #ffdc00;
        font-size: 18px;
        opacity: 0;
        text-align: center;
      }
    }

    &.filled label::after {
      opacity: 1 !important;
    }

    &.hover-filled label::after {
      opacity: 0.67;
    }
  }
}
