@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

section.data-bar {
  width: 100%;
  background-color: #e0e4f1;
  padding: 0.25rem;
  color: vars.$navbar-color;
  overflow-x: hidden;

  .card-scroller {
    margin: 0 auto;
    max-width: calc(100vw - 0.5rem);
    width: 1200px;

    .simplebar-content {
      @extend %flex-row;
    }
  }

  .card,
  .card-horizontal {
    @include pres.panel-padding-radius(0.5rem 1rem, 1rem);
    margin: 0.25rem;
    max-width: 600px;
    flex-wrap: wrap;
    flex-shrink: 0;

    p.title,
    p.secondary {
      font-size: vars.$font-size-xs;
      line-height: vars.$font-size-xs;
      letter-spacing: 1px;
      text-align: center;
      color: #647587;
    }

    p:last-child {
      margin: 0;
    }

    table {
      border-collapse: collapse;

      td,
      th {
        border-color: transparent;
        padding: 0.25rem;
      }
    }
  }

  .card-horizontal {
    @extend %flex-row-jc-ac;
  }

  .feature-number {
    p.main,
    p.main-2 {
      font-size: vars.$font-size-md;
      line-height: vars.$font-size-md;
      text-align: center;
      letter-spacing: 2px;

      b {
        position: relative;

        i {
          font-size: 30px;
          line-height: 30px;
          position: absolute;
          right: calc(100% + 0.25rem);
          top: 0.5rem;
        }
      }
    }

    p.main-2 {
      font-size: vars.$font-size-sm;
      line-height: vars.$font-size-sm;
    }
  }

  .table-card {
    table {
      td,
      th {
        line-height: vars.$font-size-xs;
      }

      td {
        font-weight: bold;
        text-align: right;
      }

      th {
        font-weight: normal;
        text-align: left;
        font-size: 14px;
      }
    }
  }

  #assignment-type-chart {
    table {
      margin-left: 1rem;

      tr {
        display: block;
      }

      td {
        font-size: vars.$font-size-xxs;
        line-height: vars.$font-size-xxs;
      }

      td.num {
        color: vars.$pale-text-color;
      }

      .color-box {
        @include pres.width-height(1rem);
        display: inline-block;
        border-radius: 4px;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
    }
  }

  .live-dot {
    @include pres.width-height(0.5rem);
    content: '';
    position: absolute;
    left: calc(100% + 0.5rem);
    top: calc(50% - 0.25rem);
    background-color: #ff0000;
    border-radius: 50%;
    animation: live-pulse 2s infinite linear;

    @keyframes live-pulse {
      0% {
        box-shadow: 0 0 0 0px white, 0 0 0 0px #ff0000, 0 0 0 2px white;
      }
      10% {
        box-shadow: 0 0 0 0px white, 0 0 0 2px #ff0000, 0 0 0 4px white;
      }
      20% {
        box-shadow: 0 0 0 4px white, 0 0 0 4px #ff0000, 0 0 0 4px white;
      }
      25% {
        box-shadow: 0 0 0 0px white, 0 0 0 0px #ff0000, 0 0 0 2px white;
      }
      100% {
        box-shadow: 0 0 0 0px white, 0 0 0 0px #ff0000, 0 0 0 2px white;
      }
    }
  }
}
