@use '../../styles/variables' as vars;
@use '../../styles/presets' as pres;

#feedback-page {
  .feedback-col {
    padding: 1rem;
    flex: 0 1 50%;
  }

  .feedback-review-window,
  #feedback-form-container {
    min-width: 1px;
  }

  .review-submission-window {
    height: 100%;
  }

  &.row {
    @include pres.restrict-to-view-height(2rem);
    padding: 1rem 0.5rem;

    .feedback-col {
      flex: 0 1 50%;
      padding: 0.5rem;
    }
  }

  &.column {
    flex-direction: column !important;
    padding: 0.25rem 0;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .feedback-col {
      padding: 0.25rem 0.5rem !important;
    }
  }
}

.feedback-review-window {
  margin-bottom: 1rem;

  .main-row {
    padding-bottom: 1rem;

    .details {
      @extend %flex-row;
      align-items: baseline;

      p.comment-name {
        font-size: vars.$font-size-xs;
        margin-right: 1rem;
        font-style: italic;
      }

      .prompt-btn {
        @extend %default-btn;
        text-transform: uppercase;
        letter-spacing: 1px;
        word-spacing: 1px;
        font-size: 12px;
        background-color: #e5e5e5;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        &:hover {
          background-color: #d7d7d7;
        }
      }
    }

    .ctrls {
      min-height: 1px;
      .icon-btns {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  .review-display-wrapper {
    .comment-quotes-wrapper {
      @extend %flex-col;
      align-items: flex-start;
    }
  }
}

#feedback-form-container {
  width: auto;

  table.rubric-table {
    max-width: 640px;
    margin-bottom: 1rem;
  }

  .peer-form-body {
    align-items: flex-start;
  }
}

@include pres.bp-tablet-lg {
  #feedback-page {
    flex-direction: column;
  }
}

@include pres.bp-laptop-sm {
  #feedback-form-container {
    margin-bottom: 2em;
  }
}
