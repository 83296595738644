@use '../../../styles/presets' as pres;
@use '../../../styles/variables' as vars;

#student-details-page {
  padding: 2rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  #student-card {
    flex: 0 0 auto;
    margin-bottom: 1rem;
    flex-wrap: wrap;

    .info-wrapper {
      margin-left: 1rem;
      h1 {
        font-size: vars.$font-size-sm !important;
      }
    }
  }

  button#back-btn {
    vertical-align: middle;

    i {
      vertical-align: middle;
      margin-right: 0.5rem;
      font-size: vars.$font-size-xs;
    }
  }

  .tabs {
    align-self: stretch;
    flex: 1 0 auto;
  }

  .review-submission-window {
    margin: 1rem;
    max-width: 1000px;

    embed {
      min-height: 90vh;
    }
  }

  #overview-tab {
    @extend %flex-row-jc-ac;

    #overview-card {
      margin: 1rem 0;
      flex-grow: 0;
    }
  }

  #submission-tab {
    @extend %flex-row-jc;
    flex-wrap: wrap;

    .review-submission-window {
      flex-grow: 1;
    }
  }

  #reviews-received-tab,
  #reviews-sent-tab,
  #evals-received-tab,
  #evals-sent-tab {
    display: flex;
    flex-direction: column;
    align-items: center;

    #reviewers-table,
    #submitters-table,
    #eval-evaluators-table,
    #eval-peers-table {
      margin: 1rem;
      flex-direction: column;
      flex-grow: 0;

      table,
      th,
      td {
        border: 0;
      }
      table {
        border-collapse: collapse;
      }

      .delete i {
        color: vars.$cancel-color !important;
      }
    }

    .review-wrapper,
    .eval-wrapper {
      display: block;
      margin: 1rem;
      padding: 1rem 2rem 2rem 2rem;

      h2 {
        text-align: center;
        margin-bottom: 1rem;
      }

      h3 {
        text-align: center;
        margin-bottom: 2rem;
      }

      section {
        margin-bottom: 1rem;
        border-bottom: 1px solid vars.$border-soft-color;

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
      }

      section.dialogue-container {
        @extend %flex-col;
        align-items: center;

        blockquote {
          font-size: vars.$font-size-xxs;

          .feedback-score {
            margin-top: 0.5rem;
            text-align: right;

            b {
              color: white;
              background-color: vars.$primary-color-2;
              padding: 0.2rem 0.5rem;
              border-radius: 10rem;
              font-weight: bold;
            }
          }
        }

        blockquote.left {
          align-self: flex-start;
        }

        blockquote.right {
          align-self: flex-end;
        }
      }

      section.rating-table {
        @extend %flex-row-jc;

        th {
          background-color: white;
        }

        th,
        td {
          text-align: left;
        }

        td.score {
          text-align: center;

          span {
            color: white;
            background-color: vars.$primary-color-2;
            padding: 0.2rem 0.5rem;
            border-radius: 10rem;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }

      .incomplete-action-txt {
        opacity: 0.6;
      }
    }
  }

  .no-progress-message {
    margin: 2rem;
    display: inline-block;
    flex-grow: 0;
  }

  .submission-for-student {
    margin-top: 2em;
  }

  .status-wrapper {
    @extend %flex-row-jc-ac;
    margin-bottom: 1rem;
    font-weight: bold;

    .target {
      margin-right: 1rem;
    }

    a {
      font-weight: normal;
      margin: 0 0 0 1rem;
    }
  }

  .complete-status,
  .incomplete-status {
    vertical-align: middle;
    text-align: center;

    span,
    i {
      vertical-align: middle;
      font-size: vars.$font-size-xxs;
    }
  }

  .complete-status {
    color: darken(vars.$success-color, 15%);
  }

  .incomplete-status {
    color: darken(vars.$cancel-color, 10%);
  }

  #grades-tab {
    @extend %flex-row-jc;
    flex-wrap: wrap;
    flex-grow: 0;
    padding: 1rem;
    margin-bottom: 4rem;

    .panel-sm {
      margin: 1rem;
      flex-direction: column;
      flex-grow: 0;
    }

    .grades-card {
      max-width: 500px;
    }

    .accuracy-card {
      p,
      h3 {
        text-align: center;
      }

      h3 {
        text-decoration: underline;
      }
    }
  }

  .reset-reviews-accordion {
    width: 100%;
    max-width: 1000px;
  }
}

.submission-history {
  @extend %flex-col-ac;
  flex-basis: 100%;

  .panel-sm {
    flex-direction: column;

    .title {
      font-size: vars.$font-size-xs;
    }

    table {
      font-size: vars.$font-size-xxs;

      .type-cell {
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
}

#group-roster-details-page {
  #group-container {
    margin-bottom: 1rem;
  }
}

@include pres.bp-mobile-lg {
  #student-details-page {
    padding: 1rem;
  }
}

@include pres.bp-mobile-lg {
  #student-details-page {
    padding: 0;
  }
}

.reflection-container {
  @extend %flex-col-ac;
}
