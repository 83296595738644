@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

nav.mininav {
  @extend %flex-row-ac;
  justify-content: flex-start;
  background-color: transparent !important;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 7000;
  box-shadow: inset 0 4rem 4rem vars.$background-color;
  margin-bottom: 1rem;

  @include pres.bp-height {
    position: relative !important;
  }

  a {
    margin-right: 1px;
    padding: 1rem;
    color: vars.$navbar-color !important;
    font-size: vars.$font-size-xs;
    line-height: vars.$font-size-xs;
    font-weight: bold;
    text-decoration: none;
    border-radius: 2px 2px 0 0;

    &:hover {
      background-color: #ebebeb !important;
    }
  }

  a.selected {
    background-color: #ebebeb;
    color: #006fb8 !important;
    box-shadow: inset 0 -0.25rem #0099ff;
  }
}

@include pres.bp-tablet-lg {
  nav.mininav {
    a {
      font-size: vars.$font-size-xxs;
      padding: 0.75rem;
    }
  }
}
