@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

@mixin nav-arrow($code) {
  font-family: 'Material Icons';
  content: $code;
  font-size: vars.$font-size-sm;
  position: absolute;
  color: #afafaf;
  width: 2rem;
  text-align: center;
}

.peer-breadcrumb {
  @extend %flex-row-ac;
  background-color: transparent !important;

  ol {
    @extend %default-list;

    li {
      float: left;
      position: relative;

      &:not(:last-child) {
        padding-right: 2rem;

        &::after {
          @include nav-arrow('navigate_next');
        }
      }
    }

    li.return {
      margin-left: 2rem;

      &::before {
        @include nav-arrow('navigate_before');
        position: absolute;
        right: 100%;
      }
    }
  }

  i,
  button.crumb {
    @extend %default-btn;
    color: #8a8a8a;
  }

  i {
    margin: 0 0.5rem;
    font-size: vars.$icon-size;

    &:first-child {
      margin: 0 0.5rem 0 0;
    }
  }

  button.crumb {
    font-size: vars.$font-size-sm;
  }

  .selected {
    font-weight: bold;
    color: vars.$navbar-color !important;
  }
}

@include pres.bp-tablet-lg {
  .peer-breadcrumb {
    li::before,
    li::after {
      font-size: vars.$font-size-xs !important;
    }

    button.crumb {
      font-size: vars.$font-size-xs !important;
    }
  }
}
