@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.peer-accordion {
  margin-bottom: 0.5rem;

  .accordion-heading {
    @extend %flex-row-ac;
    @include pres.panel-padding-radius(0.5rem 1rem, 0.25rem);
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 3px 6px 4px #0000002d;
    position: relative;

    .heading-left-wrapper {
      @extend %flex-row-ac;

      .LMS-connection {
        text-align: center;
      }
    }

    .custom-heading-content {
      i {
        font-size: 25px;
        user-select: none;
        pointer-events: none;
      }
    }

    button.accordion-toggle {
      @extend %default-btn;
      margin-right: 2rem;
      border-radius: 0.25rem;
      padding: 0.25rem;
      user-select: none;
      line-height: normal;

      &:hover {
        background-color: #00000025;
      }
    }

    button.accordion-name-edit {
      @extend %default-btn;
      word-break: break-word;
      border-radius: 4px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    input[type='text'] {
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem;
      margin: 0 !important;
    }

    label {
      margin: 0 !important;
    }

    h2 {
      font-size: vars.$font-size-xs;
      color: vars.$icon-color;
      margin-bottom: 0;

      i {
        font-size: vars.$font-size-xs;
      }
    }

    .reorder-controls {
      @extend %flex-row-ac;
      padding-right: 0.6rem;

      .reorder-arrows {
        @extend %flex-col;
        margin-right: 2rem;
      }
    }
  }

  .accordion-contents {
    @extend %flex-col;
    @include pres.panel-padding-radius(2rem, 0 0 0.25rem 0.25rem);
    align-content: stretch;
    margin-top: -0.25rem;
    box-shadow: 0 3px 6px 4px #0000002d;

    label {
      font-size: vars.$font-size-sm;
      font-weight: bold;
      margin: 0;

      textarea,
      input,
      div {
        font-size: vars.$font-size-xs;
        font-weight: normal;
      }
    }

    input {
      margin: 0.5rem 1rem;
      text-align: center;
      width: 6rem;
    }

    .accordion-row {
      @extend %flex-row-jc;
      align-items: stretch;
      flex-wrap: wrap;
      flex-grow: 1;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .accordion-col {
      @extend %flex-col;
      flex: 1 1 50%;
      padding: 1rem;
    }

    .tags-input,
    .rich-editor,
    .list-editor {
      margin-top: 0.5rem;
    }
  }
}

.peer-accordion.selected {
  margin: 1rem 0;
  transition: margin 0.1s;

  &:first-child {
    margin: 0 0 1rem 0;
  }
}

.peer-accordion.selected .accordion-heading {
  background-color: vars.$primary-color-2;
  transition: background-color 0.1s;

  i,
  h2,
  label,
  .accordion-toggle,
  button.accordion-name-edit,
  .reorder-controls,
  span {
    color: white !important;
    transition: color 0.1s;
  }
}

@include pres.bp-mobile-lg {
  .accordion-name-edit {
    i {
      font-size: calc(#{vars.$icon-size} / 2) !important;
    }
  }
}
