@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;
@use './purchasing/purchasing';

.login-row,
.register-row,
.logout-row {
  padding: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-row,
.register-row,
.reset-password-row {
  .login-container,
  .registration-form {
    margin-top: 5rem;
  }

  .login-container,
  .change-password-form,
  .profile-form,
  .forgot-password-form,
  .reset-password-form {
    flex: 0 0 auto;
  }

  .login-container {
    flex-direction: column;
    padding: 3rem;

    form {
      input,
      button {
        width: 100%;
      }

      input {
        display: block;
        margin-bottom: 1rem;
      }

      button {
        margin: 1rem 0;

        h1 {
          font-weight: normal;
          font-size: inherit !important;
          margin: 0 !important;
        }
      }
    }

    a#forgot-pwd {
      display: block;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  p.invalid-log {
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #ffcaca;
  }
}

.login-row {
  .login-container {
    form {
      border-bottom: 1px solid vars.$border-soft-color;
      margin-bottom: 2rem;
    }
  }
}

.register-row {
  span#i-am-a {
    margin-right: 1ch;
    align-self: center;
    font-weight: bold;
  }

  .input-group:last-child {
    .inputs-container-row {
      align-items: center;
      label {
        margin: 0;
      }
    }
  }

  .password-wrapper {
    margin-bottom: 0.5rem;
    position: relative;

    &:last-child {
      margin: 0;
    }

    input {
      width: 100%;
      padding-right: 2.5rem;
    }

    button.toggle-password {
      @extend %default-btn;
      @include pres.inset(0, 0.5rem, 0.5rem, auto);
      position: absolute;
    }
  }

  .share-btn {
    padding: 0.5rem !important;

    i {
      margin-right: 0.25rem !important;
    }
  }

  .category-menu {
    max-width: 100vw;
  }
}

.logout-row {
  .logout-container {
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: vars.$font-size-md;
      animation: fadeOut 1s linear infinite alternate;
    }
  }
}

.page-not-found,
.error-fallback-page {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: vars.$font-size-lg;
  }

  button {
    margin-top: 1rem;
  }
}

#email-verify-page {
  @extend %flex-col-jc-ac;
  @include pres.width-height(100%);

  #prompt {
    @include pres.panel-padding-radius(4rem, 2rem);
    max-width: 512px;
    text-align: center;
    animation: fadeIn 0.5s;
  }
}

#email-verify-page #prompt {
  i {
    font-size: 102px;
    color: rgb(192, 192, 192);
  }

  i#done-icon {
    color: vars.$success-color !important;
  }

  i#error-icon {
    color: vars.$cancel-color !important;
  }

  button {
    margin: 1rem 0;
  }
}

.login-row,
.register-row,
.reset-password-row,
#email-verify-page {
  position: relative;

  img {
    position: absolute;
    top: 3rem;
  }
}

.change-password-form {
  min-width: 256px;
}

#purchase-history-table {
  position: relative;
}

#report-form-content {
  @extend %flex-col-ac;

  select {
    margin-bottom: 1rem;
  }

  textarea {
    min-width: 300px;
    min-height: 140px;
  }

  input[type='checkbox'] {
    margin-right: 0.5rem;

    & + label {
      margin: 1rem 0 1rem 0 !important;
    }
  }
}

#notifications-page {
  justify-content: center;
  background-color: darken(vars.$navbar-color, 5%);
  animation: fadeIn 0.25s;

  .notifs-container {
    border-top: 1px solid lighten(vars.$navbar-color, 5%);
    background-color: vars.$navbar-color;
    max-width: 800px;
    flex-grow: 1;
    position: relative;

    @media (max-width: 800px) {
      max-width: 100vw;
    }

    .heading,
    .ctrls {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .notifications-wrapper {
      min-height: 8rem;
      max-height: calc(100vh - #{vars.$navbar-height} - 7.5rem) !important;
    }

    .notification {
      padding: 1rem !important;
    }

    .heading {
      padding: 0 0.5rem;
      position: relative;

      h1 {
        font-size: vars.$font-size-sm;
        line-height: vars.$font-size-sm;
        margin: 1rem 0 0 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid #758591;
      }

      button.close {
        @include pres.width-height(2rem);
        margin: 0 0.5rem;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 !important;

        i {
          color: white !important;
          font-size: 1rem;
        }
      }
    }

    .ctrls {
      margin: 0;
      padding: 1rem 0.5rem 0 0.5rem;
    }

    a#notifs-page-link {
      display: none;
    }
  }
}

form#email-preferences-form,
form#accessibility-options-form {
  padding: 1rem;
  position: relative;

  .radio-wrapper {
    input[type='radio'],
    input[type='checkbox'] {
      margin-right: 0.5rem;
    }

    input[type='radio'],
    input[type='checkbox'],
    label {
      cursor: pointer;
    }
  }

  button {
    margin-top: 1rem;
  }

  .alert-bar {
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
}
