@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;

.lti-connection-container {
  margin: 2rem 4rem 4em 2rem;
}

.lti-connection-instruction {
  padding: 0rem 1rem 0rem 1rem;
}

#delete-btn {
  margin: 1rem;
}

.maxScore {
  @extend %flex-row-jc;
}

#maxScore-btn {
  border: none;
  background-color: transparent;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  white-space: nowrap; /* Ensure text is in one line */
  background-color: #225577;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 5%; /* Position the tooltip vertically centered */
  left: 90%; /* Position the tooltip to the right of the icon */
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
