@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.table-input {
  min-width: 0;
  flex-shrink: 1;

  table {
    border-spacing: 0;
    table-layout: fixed;
  }

  tr.selected {
    position: relative;
    box-shadow: 0 0 0 3px lighten(vars.$primary-color-2, 40%);
    border-radius: 1px;
    transition: box-shadow 0.1s;
  }

  td {
    padding: 0 !important;

    input {
      border-radius: 0;
      border: none;
      width: 100%;
    }
  }

  .ctrls-wrapper {
    margin-top: 0.5rem;

    button {
      @extend %default-btn;
      @include pres.width-height(2rem);
      border-radius: 50%;
      padding: 0;
      background-color: vars.$primary-color-2;
      margin-right: 0.25rem;

      &:hover {
        background-color: lighten(vars.$primary-color-2, 10%);
      }

      i {
        vertical-align: middle;
        color: white;
      }
    }
  }
}
