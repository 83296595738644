@use "presets" as pres;
@use "variables" as vars;

@keyframes click-highlight {
  0% {
    opacity: 1;
  }
  100% {
    @include pres.width-height(3rem);
    opacity: 0;
  }
}

.focus-pulse {
  animation: focus-pulse-animation 1s infinite;
  position: relative;
  z-index: 1000;

  @keyframes focus-pulse-animation {
    0% {
      box-shadow: 0 0 0 0.1rem rgba($color: #a6eaff, $alpha: 0.8), 0px 0px 14px 100vw rgba($color: #000000, $alpha: 0.4);
    }
    80% {
      box-shadow: 0 0 0 2rem rgba($color: #a6eaff, $alpha: 0), 0px 0px 14px 100vw rgba($color: #000000, $alpha: 0.4);
    }
    100% {
      box-shadow: 0 0 0 2rem rgba($color: #a6eaff, $alpha: 0), 0px 0px 14px 100vw rgba($color: #000000, $alpha: 0.4);
    }
  }
}

.flash-animation {
  animation: flash 0.5s 2;

  @keyframes flash {
    0% {
      filter: brightness(100%);
    }
    50% {
      filter: brightness(150%);
    }
    100% {
      filter: brightness(100%);
    }
  }
}

.pointer-animation-right,
.pointer-animation-top {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
  }
}

.pointer-animation-right::after {
  right: -1rem;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #43b0c3;
  animation: spinning-pointer-right 1s infinite;

  @keyframes spinning-pointer-right {
    0% {
      transform: translateX(0) scaleX(1) rotate3d(0, 0, 0, 0);
      border-right: 10px solid #43b0c3;
    }
    25% {
      border-right: 10px solid #2d719f;
    }
    50% {
      transform: translateX(1rem) scaleX(1.6) rotate3d(1, 0, 0, 0.5turn);
      border-right: 10px solid #43b0c3;
    }
    75% {
      border-right: 10px solid #2d719f;
    }
    100% {
      transform: translateX(0) scaleX(1) rotate3d(1, 0, 0, 1turn);
      border-right: 10px solid #43b0c3;
    }
  }
}

.pointer-animation-top::after {
  top: -2.5rem;
  border-top: 10px solid #43b0c3;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  animation: spinning-pointer-top 1s infinite;

  @keyframes spinning-pointer-top {
    0% {
      transform: translateY(0) scaleY(1) rotate3d(0, 0, 0, 0);
      border-top: 10px solid #43b0c3;
    }
    25% {
      border-top: 10px solid #2d719f;
    }
    50% {
      transform: translateY(1rem) scaleY(1.6) rotate3d(0, 1, 0, 0.5turn);
      border-top: 10px solid #43b0c3;
    }
    75% {
      border-top: 10px solid #2d719f;
    }
    100% {
      transform: translateY(0) scaleY(1) rotate3d(0, 1, 0, 1turn);
      border-top: 10px solid #43b0c3;
    }
  }
}

@keyframes subtle-pop-in {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.ghost {
  animation: ghosting 1s infinite alternate;

  @keyframes ghosting {
    0% {
      background-color: rgb(225, 225, 225);
    }
    100% {
      background-color: rgb(250, 250, 250);
    }
  }
}
