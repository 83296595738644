@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.list-editor {
  @extend %flex-col;
  flex-grow: 1;

  .list-table-wrapper {
    flex-grow: 1;

    table {
      @include pres.width-height(100%);
      border-collapse: inherit;
      border-spacing: 0px;

      th,
      td {
        @extend %text-break-to-ellipsis;
        padding: 0.5rem;
        max-width: 0;
      }

      th:first-child,
      td:first-child {
        width: 4rem;
        text-align: center;
      }

      thead {
        th {
          background: white;
          color: vars.$pale-text-color;
          font-weight: normal;
        }

        tr:first-child th:first-child {
          border-radius: 0.5rem 0 0 0;
        }

        tr:first-child th:last-child {
          border-radius: 0 0.5rem 0 0;
        }
      }

      tbody {
        background-color: #eeeeee;

        tr:hover {
          cursor: text;
        }

        tr:last-child:hover {
          cursor: auto;
        }

        tr td:first-child {
          max-width: 4rem;
        }

        tr.selected {
          background-color: #00000011;

          textarea {
            overflow: visible !important;
            white-space: normal !important;
          }
        }

        textarea {
          padding: 0;
          border: none;
          background-color: transparent;
          border-radius: 2px;
          width: 100%;
          min-width: 100%;
          text-align: left;
          margin: 0;
          box-shadow: none;
        }

        textarea {
          @extend %text-break-to-ellipsis;
          height: auto;
          min-height: 3rem;
        }

        .empty {
          color: darken(vars.$pale-text-color, 5%);
          cursor: pointer;
        }
      }
    }
  }

  .list-ctrls {
    margin-top: 0.5rem;

    input[type='number'] {
      max-width: 3rem;
      padding: 0.25rem;
      text-align: center;
      margin: 0 0.5rem;
    }

    button {
      @extend %default-btn;
      @include pres.width-height(2rem);
      border-radius: 50%;
      padding: 0;
      background-color: vars.$primary-color-2;
      margin-right: 0.25rem;

      &:hover {
        background-color: lighten(vars.$primary-color-2, 10%);
      }

      i {
        vertical-align: middle;
        color: white;
      }
    }
  }
}
