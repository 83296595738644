@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.ranked-choice {
  margin: 0;

  .choice {
    @extend %flex-row-ac;
    position: relative;
    border: 2px solid vars.$border-soft-color;
    padding: 1rem;
    border-radius: 2rem;
    margin-bottom: 0.25rem;

    .rank {
      @include pres.width-height(1.5rem);
      display: inline-block;
      margin-right: 0.5rem;
      background-color: gray;
      color: white;
      border-radius: 50%;
      text-align: center;
    }

    input[type='checkbox'] {
      @extend %absolute-and-fill-space;
      opacity: 0;
      cursor: pointer;

      &:focus-visible + label {
        @extend %focus-visible-style;
      }
    }

    label {
      margin: 0 0.5rem;
    }
  }

  .choice.checked {
    border-color: vars.$primary-color-2;

    .rank {
      background-color: vars.$primary-color-2;
    }
  }
}
